///*------------------------------------*\
//    #PAGE-HEAD
//\*------------------------------------*/

// Internal variables.
$logo-height:               35px; // 140px
$mobile-navbar-height:      $logo-height + (2*$inuit-base-spacing-unit);
$mobile-menu-img-height:    170px;

.page-head,
%page-head {
    position: relative;
    margin-bottom: $inuit-base-spacing-unit;
    // @include material-shadow(1);
    @include media-query(palm) {
        background-color: transparent;
    }
}
/*    @include media-query(portable) {
        background: url(../img/header/cover.jpg) no-repeat center right;
        background-size: cover;
    }*/
%page-head-bg-container {
    position: absolute;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}
.page-head__bg-container {
    @extend %page-head-bg-container;
    height: 100%;
    background-color: #fff;
}
.page-head__bg-container--responsive {
    @extend %page-head-bg-container;
    height: $mobile-navbar-height;
    background-color: $color-main;
    @include material-shadow(2);
}
.page-head__bg__img {
    width: 100%;
    height: 100%;
    background: no-repeat center center;
    background-size: cover;
    &.bg-img_background_default     { background-image: url(../img/header/covers/snapshots/background_default.jpg); }
    &.bg-img_background_special       { background-image: url(../img/header/covers/snapshots/background_special.jpg); }
    
    .page-head__bg__video.js-hidden ~ &:after,
        .page-head__bg-container > &:first-child:after {
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        content: ' ';
    }
}
.page-head__bg__video {
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    &.bg-vid_morning {
        top: 75%;
        left: 50%;
        transform: translate(-50%,-75%);
    }
    &.bg-vid_day {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &.bg-vid_evening {
        top: 90%;
        left: 50%;
        transform: translate(-50%,-90%);
    }
    &.bg-vid_sunset {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &.bg-vid_night {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &.bg-vid_odv {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &.bg-vid_odv2 {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
.page-head--responsive {
    @include media-query(palm) {
        margin-left: -$inuit-base-spacing-unit--small !important;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.page-head__mobile-offset {
    width: 100%;
    height: $mobile-navbar-height;
}
.page-head__container-mobile {
    @include media-query(palm) {      
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: $z-index-slidepanel;        
    }
}
.page-head__menu-img-mobile {
    height: $mobile-menu-img-height;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/aviatur_assets/img/header/menu-img-mobile.jpg);
    @media screen and (min-width:450px) and (max-width:721px){
        height: 270px;
    }
}
// PILAS
.page-head__menu-mobile {
        @include span(1 of 6);
    }
.page-head__menu-mobile img {
    max-height: $logo-height;
    margin-left: $inuit-base-spacing-unit--small;
}


.page-head__logo {
    float: left;
    @include media-query(palm) {
        width: 100%;
        position: absolute;
        z-index: -1; // seat beneath menu icon
    }
    @include media-query(lap) {
        margin-left: $inuit-base-spacing-unit;
    }
    @include media-query(lap-and-up) {
        margin-top: $inuit-base-spacing-unit;
    }
}
.page-head__aviatur {
    max-height: $logo-height;
    @include media-query(lap-and-up) {
        float: left;
        padding-right: 10px;
    }
}

// PROMO TIME !
.page-head--promo {
    background: url(../img/header/cover.jpg) no-repeat center center;
    background-size: cover;
}
.page-head--promo--luna {
    background: none;
    position: relative;
}
.page-head--promo * {
    color: $color-page-head-promo;
}
.page-head-edit-background {
    position: absolute;
    top: 4px;
    width: auto;
    padding: 9px 9px;
    margin: 1px 0 0 5px;
    border: 2px solid #00353e;
    border-top-width: 0;
    color: #fff;
    background-color: #003945;
    font-weight: 600;
    font-size: 90%;
    a {
        color:#fff;
    }
}
.contac-whatsapp{
        float: right;
}
.page-head__bg-container>.page-head__bg__img:first-child:after, .page-head__bg__video.js-hidden~.page-head__bg__img:after {
    background-color: rgba(0,0,0,.0);
}

.page-head__contact{
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    padding: 10px 15px;
    background-color: $color-head-gree;
    border-radius: 0 0 10px 10px;
    @media screen and (min-width:1024px) and (max-width:1180px) {
        margin-right: 3%;
    }
    .list-bare{
        width: 100%;
        float: left;     
        // li:last-child{
        //     border-right: 0px;           
        // }    
        .page-head__nbr-call{
            display: initial;
            margin-right: 10px;
            padding-right: 10px;          
            border-right: 1px solid #ccc;  
            color: $color-gree-text;  
            i{
                margin-top: -3px;
                margin-right: 5px;
            }
            span{
                font-weight: normal;
            }       
            b{
                vertical-align: middle;
            }
            .whatsapp-header {
                width: 18px;
                margin-right: 2px;
                position: relative;
                top: -2px;
            }  
            a{
                color: $color-gree-text;
            }
            a:hover, a:focus{
                color: $color-gree-text !important;
            }    
        }
        .not-border{
            border-right: 0px;   
        }
    }
}
#nav-sub {
    display: none;
    margin-left: 0;
    width: 200px;
    position: absolute;
    background: #fff;
    z-index: 10;
    box-shadow: 0 1px 4px 1px rgba(65,65,65,.478431);
    top: 30px;
    height: auto;
    border-radius: 4px;
    list-style: none;
}

#nav-mail:hover #nav-sub{
    display: grid;
    li{        
        height: auto;
        padding: 10px;
        float: left;
        text-align: left;
    }
}

.list-bare
 > .page-head__nbr-call:last-of-type {    
    display: none;
}