/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

[role="button"],
[type="button"],
[type="submit"] {
    outline: none;
}

.btn {
    transition: .2s;
    @include font-weight(light);
    @include btn-color($color-btn);
    box-shadow: 0 0 2px rgba(0,0,0,.08),0 2px 4px rgba(0,0,0,.16);
    color: white;
    border-radius: 0;
    outline: none;

    box-shadow: 0 1px 6px 0 rgba(0,0,0,.44);
    padding: 10px 20px;
    border-radius: 50px;
    border: none;
}

// Styling btn-group
.btn-group {
    display: inline-block;
    border-radius: $base-round;
    box-shadow: 0 0 2px rgba(0,0,0,0), 0 2px 4px rgba(0,0,0,0);
    & > .btn {
        float:left;
        position: relative;
        border-color: transparent;
        box-shadow: none;
        @include attention() {
            z-index: $z-index-btn-group__btn-hover;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0);
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
        &:not(.btn--inactive) {
          color: $color-brand;
          box-shadow:none;
          padding: 10px;
          background: transparent;
          border: 1px solid $color-brand;
        }
    }
}


.btn--secondary {
    background: none;
    color: $color-btn;
    @include attention() {
        background: none;
        color: darken($color-btn, 10%);
    }
    @include attention(active) {
        background: $color-btn;
        color: white;
    }
}
.btn--accent {
    @include btn-color($color-btn-accent);
}
.btn--slim {
    @extend .btn;
    @extend .btn--small;
    font-size: .8em;
    font-weight: normal;
}
.btn--positive {
    @include btn-color($color-btn-positive);
}
.btn--negative {
    @include btn-color($color-btn-negative);
}
.btn--submit {
    @include font-weight(regular);
    @include btn-color($color-btn-submit);
    font-size: 1rem;
}
.btn-full-home{
    letter-spacing: 0.2rem !important;
    font-size: 1.2rem !important;
}
.btn--inactive {
    @include btn-inactive($color-btn);
}
.btn--static {
    @include font-weight(regular);
    @include btn-static(#cfcfcf);
}


%btn-social {
    padding: 4px 12px;
}
.btn--facebook {
    @extend %btn-social;
    @include btn-color(#3b5998);
}
.btn--twitter {
    @extend %btn-social;
    @include btn-color(#1b95e0);
}
.btn--google {
    @extend %btn-social;
    @include btn-color(#db4437);
}


.buttonize {
    display: inline-block;
    padding: $inuit-base-spacing-unit--small;
    background-color: rgba($gray-base, .03);
    border: 1px solid rgba($gray-base, .06);
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    @include material-shadow(1);
    &:hover {
        background-color: rgba($gray-base, .06);
        border-bottom-color: rgba($gray-base, .09);
    }
    &:active, &.is-selected {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
}


.btn--scroll-top {
    display: none;
    width: 48px;
    height: 57px;
    position: fixed;
    bottom: -26px;
    right: $inuit-base-spacing-unit;
    padding: $inuit-base-spacing-unit--small;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    background-color: $color-main;
    box-shadow: 0 0 3px 1px rgba(64, 64, 64, 0.4);
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}
.btn-explore{
    border: 1px solid #444 !important;
    color: #444 !important;
}
.btn-explore:hover, .btn-explore:active, .btn-explore:visited, .btn-explore:focus{
    border: 1px solid #444 !important;
    background: #444 !important;
    color: white !important;
}
.search-hotel__col-submit .btn--submit{
    font-size: 1rem !important;
    letter-spacing: 0;
    padding: 10px 10px;
}


/*------------------------------------*\
    #PILLS
\*------------------------------------*/
/*---
section: Buttons
title: pills
---

container
.pill

inline child elements

```example:html
<p>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Latam" href="#ff_1_LA" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_LA.png"><span class="hidden-palm">&nbsp;Latam</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de VivaColombia" href="#ff_1_FC" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_FC.png"><span class="hidden-palm">&nbsp;VivaColombia</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Avianca" href="#ff_1_AV" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_AV.png"><span class="hidden-palm">&nbsp;Avianca</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Satena" href="#ff_1_Z0" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_Z0.png"><span class="hidden-palm">&nbsp;Satena</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de EasyFly" href="#ff_1_VE" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_VE.png"><span class="hidden-palm">&nbsp;EasyFly</span>
    </a>
</p>
```
*/
.pill {
    display: inline-block;
    &:not(:nth-child(1)) {
        margin-left: $inuit-base-spacing-unit--small;
    }
    // & > .pill-height-helper {
    //     height: 100%;
    // }
    & > * {
        vertical-align: middle;
    }
}
.content-btn-airline{
    @include btn-color($color-main);
    @include media-query (desk){
        padding-bottom: 7px;
    }
}
.btn-floating{
    border: 0px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    padding: 10px;
    line-height: 1.6;
    background: #005eb8;
    color: white;
}
.btn-floating .material-icons-round{
    font-size: 1rem;
}