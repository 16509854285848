/*------------------------------------*\
    #SELECT-GROUP
\*------------------------------------*/

select {
    position : relative;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}

.select--arrow {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9ImFycm93X3g1Rl9kb3duIj48cG9seWdvbiBmaWxsPSIjYmJiYmJiIiBwb2ludHM9IjUxMS44NiwxMjguMDA4IDI1NiwzODMuODUyIDAuMTQsMTI4LjAwOCIvPjwvZz48ZyBpZD0iQ2FwYV8xIj48L2c+PC9zdmc+)
        no-repeat calc(100% - 9px) calc(50% + 2px);
    background-size: 17px;
    background-color: white;
    font-size: 0.9em !important;
}
.select--arrow--hide-palm {
    @include media-query(palm) {
        background: none;
    }
}
.select--clock {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48ZyBmaWxsPSIjMjEyMTIxIj48cGF0aCBkPSJNMjU3LjEwOC4wODNDMTE1Ljc2OS4wODMgMS4xOTEgMTE0LjY2MSAxLjE5MSAyNTZjMCAxNDEuMzQgMTE0LjU3OCAyNTUuOTE2IDI1NS45MTcgMjU1LjkxNiAxNDEuMzM3IDAgMjU1LjkxNy0xMTQuNTc2IDI1NS45MTctMjU1LjkxNkM1MTMuMDI1IDExNC42NjEgMzk4LjQ0NS4wODMgMjU3LjEwOC4wODN6bTIxLjQxMyA0NTYuM2MuMDQ2LS42NTEuMDg3LTEuMzA1LjA4Ny0xLjk2OCAwLTEzLjczNS05LjYyNS0yNC44NjYtMjEuNS0yNC44NjZzLTIxLjUgMTEuMTMxLTIxLjUgMjQuODY2YzAgLjY2My4wNDEgMS4zMTYuMDg3IDEuOTY4LTk0LjA0Ny05Ljk4MS0xNjguOTgzLTg0LjkxMS0xNzguOTY5LTE3OC45NTYuNTU1LjAzMiAxLjEwNy4wNzQgMS42NzEuMDc0IDEzLjczNCAwIDI0Ljg2Ni05LjYyNiAyNC44NjYtMjEuNSAwLTExLjg3NS0xMS4xMzItMjEuNS0yNC44NjYtMjEuNS0uNTY0IDAtMS4xMTYuMDQxLTEuNjcxLjA3MiA5Ljk4OC05NC4wNjggODQuOTU4LTE2OS4wMTUgMTc5LjAzOS0xNzguOTY0LS4wOTUuOTktLjE1NyAxLjk5MS0uMTU3IDMuMDEzIDAgMTQuMzA4IDkuNjI1IDI1LjkwNSAyMS41IDI1LjkwNXMyMS41LTExLjU5OCAyMS41LTI1LjkwNWMwLTEuMDIxLS4wNjItMi4wMjItLjE1OC0zLjAxMyA5NC4xMjIgOS45NTMgMTY5LjExOCA4NC45NjEgMTc5LjA1NCAxNzkuMDg2LTEuMDg5LS4xMTUtMi4xODgtLjE5NS0zLjMxNS0uMTk1LTE0LjMwNyAwLTI1LjkwMyA5LjYyNS0yNS45MDMgMjEuNSAwIDExLjg3NCAxMS41OTcgMjEuNSAyNS45MDMgMjEuNSAxLjEyNyAwIDIuMjI3LS4wODEgMy4zMTUtLjE5Ny05LjkzMyA5NC4xMDMtODQuODk0IDE2OS4wOTYtMTc4Ljk4MyAxNzkuMDh6Ii8+PHBhdGggZD0iTTM3NS40MjQgMzU2LjM1M2wtODQuNTk0LTkxLjU3NGMuNzI5LTIuODA0IDEuMTE3LTUuNzQ2IDEuMTE3LTguNzc5IDAtMTEuNjczLTUuNzQ1LTIxLjk5OC0xNC41Ni0yOC4zMTlsLTMuMTg0LTEwMi4xNzJjMC05LjQ0MS03LjY1Mi0xNy4wOTYtMTcuMDk2LTE3LjA5Ni05LjQ0IDAtMTcuMDk2IDcuNjU0LTE3LjA5NiAxNy4wOTZsLTQuMDc1IDEwMi44MzFjLTguMzA2IDYuMzY4LTEzLjY2NyAxNi4zODYtMTMuNjY3IDI3LjY2MSAwIDE4Ljc4MyAxNC44NjggMzQuMDg3IDMzLjQ3NyAzNC44MDNsOTUuNTAyIDg5LjcyN2M2LjY3MyA2LjY3OCAxNy40OTcgNi42NzggMjQuMTc0IDAgNi42NzgtNi42NzcgNi42OC0xNy41MDEuMDAyLTI0LjE3OHoiLz48L2c+PC9zdmc+)
        no-repeat calc(100% - 9px) calc(50% + 2px);
    background-size: 17px;
    padding-right: $inuit-base-spacing-unit*2;
}

// For IE
select::-ms-expand {
    display: none;
}

.select-group {
    position: relative;
    overflow: hidden;
}

.select-group select {
    padding-left: 0;
    width: calc(100% + 18px);
}