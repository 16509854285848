/*------------------------------------*\
    #INPUT-GROUP
\*------------------------------------*/

.input-group {
    display: table;
    border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table
    width: 100%;
}

.input-group .text-input {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;
    z-index: $z-index-input-group__text-input;
    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: left;
}

.input-group-add {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}
