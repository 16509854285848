/*------------------------------------*\
    #ANIMATIONS
        Usage:
        animation: animation_name duration [easing] [delay] [iteration] [direction] [fill-mode] [play-state];
\*------------------------------------*/

/********** PROMO HEADER **********/

/*@include keyframes(scaleout100pc) {
    0%   { transform: translateX(0);}
    100% { transform: translateX(-100%);}
}
@include keyframes(scaleout300) {
    0%   { transform: translateX(0);}
    100% { transform: translateX(-300px);}
}
@include keyframes(scaleout367) {
    0%   { transform: translateX(0);}
    100% { transform: translateX(-367px);}
}
@include keyframes(animated) {
    0%   { background-position: 0 0, 0 0, 100px 0;}
    50%  { background-position: 0 0, 0 0, 100px 150px;}
    100% { background-position: 0 0, 0 0, 100px 300px;}
}*/

/********** MISC UTILITIES **********/

.fadeIn {
    animation: fade-in 1s ease-in-out 1 forwards;
}
@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeOut {
    animation: fade-out 600ms ease;
}
@include keyframes(fade-out) {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@include keyframes(slide-up) {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@include keyframes(bg-expand) {
    0% {
        background-size: 100%;
    }
    100% {
        background-size: 120%
    }
}
.bg-deexpand {
    animation: bg-deexpand 300ms ease;
}
@include keyframes(bg-deexpand) {
    0% {
        background-size: 120%;
    }
    100% {
        background-size: 100%
    }
}

@include keyframes(rotate) {
    100% {
        transform: rotate(360deg);
    }
}

.rotateY {
    animation: rotate-y 2s infinite;
}
@include keyframes(rotate-y) {
    0% {
        transform: rotateY(360deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.pulse {
    animation: pulse 1.6s ease-in-out infinite alternate;
}
@include keyframes(pulse) {
    0%, 50%, 100% {
        opacity: 1;
        transform: scale(1);
    }
    25%, 75% {
        opacity: .8;
        transform: scale(1.3);
    }
}

.animated-background {
    @include linear-gradient(left, #fbfbfb 8%, #f1f1f1 18%, #fbfbfb 33%);
    background-size: 800px 104px;
    animation: loadingFpo 1.1s linear infinite;
}
@include keyframes(loadingFpo) {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.pulseOpacity {
    animation: pulse-opacity 1.2s ease-in-out .4s 3 alternate;
    opacity: 1;
}
@include keyframes(pulse-opacity) {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .6;
    }
    100% {
        opacity: 1;
    }
}

.scrollLeft {
    animation: pull-left .6s ease-in-out 1 forwards;
}
@include keyframes(pull-left) {
    0% {
        margin-right: -$contact-slider-width;
    }
    100% {
        margin-right: 0;
    }
}

.scrollLeft2Right {
    animation: pull-left2right .6s ease-in-out 1 forwards;
}
@include keyframes(pull-left2right) {
    0% {
        margin-left: -$contact-slider-width;
    }
    100% {
        margin-left: 0;
    }
}

.scrollRight {
    animation: pull-right .6s ease-in-out 1 forwards;
}
@include keyframes(pull-right) {
    0% {
        margin-right: 0;
    }
    100% {
        margin-right: -$contact-slider-width;
    }
}

.scrollRight2Left {
    animation: pull-right2left .6s ease-in-out 1 forwards;
}
@include keyframes(pull-right2left) {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -$contact-slider-width;
    }
}
