///*------------------------------------*\
//    #BUNDLE CUSTOMER
//\*------------------------------------*/

.customer__col-booking {
    @include span(1 of 2);
    @include media-query(palm) {
        @include span(full);
    }
}
.container-transaction{
    margin-top: 30px;
    h2{
        padding-bottom: 15px;
        @include media-query(palm){
            margin-left: 10px;
        }
    }
    p{
        @include media-query(palm){
            margin-left: 10px;
        }
    }
    .contain-info-transaction{
        display: flex;
        .col-2{
            text-align: center;
            padding-top: 46px;
        }
    }
}
.contain-profile{
    margin-top: 40px;

    &>h1{
        color: $color-gree-text;
        margin-bottom: 40px;
    }
    #myprofile{
        padding-left: 0;
        .content-user{
            background-color: #005eb8;
            padding: 20px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            h1{
                text-transform: capitalize;
                color: white;
            }   
            h2{
                color: white;
            }         
        }
        .content-info-user{
            .info-data-user{
                padding: 27px 0 0 0;
                .span-profile{
                    @include font-weight(bold);
                    @include inuit-font-size(14px);
                    color: $color-main;

                }
            }
            .info-redes-user{
                padding: 10px 0 0 0;
                .img-facebook-profile{
                    border-radius: 25px;
                    width: 82%;
                }
                .name-red-social{
                    margin-top: 9px;
                }
                span{
                    @include font-weight(bold);
                    @include inuit-font-size(14px);
                    color: $color-main;
                }
            }         
            .icons-customer{
                font-size: 22px;
                border-radius: 28px;
                border: 1px solid $color-gree-text;
                padding: 4px 4px;
            }
            .icon-email{
                font-size: 17px;
                padding: 6px 2px;
                text-align: center;
                width: 33px;
                font-weight: 700;
            }
            .icon-phone{
                width: 34px;
                font-size: 20px;
                text-align: center;
                i{
                    vertical-align: initial;
                }
            }
            p{
                font-size: 12px;
                margin-bottom: 4px;
                color: $color-gree-text;
            }
        }        
        .content-info-payment{
            margin-top: 30px;
            h4{
                color: $color-gree-text;
                @include font-weight(bold);  
                margin-bottom: 4px;              
            }
            .cards{
                .subtitle{
                    @include font-size(14px);
                    color: $color-gree-text;                    
                    line-height: 1.23;
                    margin-top: 5px;
                }   
                .franchise{
                    margin-bottom: 5px;
                }
                .num-card{
                    font-size: 13px;
                }
                .content-img-new-card{
                    border: dotted 1px #666666;
                    border-radius: 50%;
                    padding: 8px;
                    text-align: center;
                    i{
                        font-size: 24px;
                        position: relative;
                        margin-top: -12px;
                        margin-left: -2px;
                    }
                }
                .add-card{
                    margin-top: 6px;
                    a{
                        @include font-size(13px);
                        color: $color-gree-text;
                    }
                }
            }
            .box-item{
                text-align: center;
                border-radius: 4px;
                border: dotted 2px #dddddd;
                padding: 20px;     
                .icon_cards_circle, .icon_newsletters_circle{
                    font-size: 60px;
                    margin-bottom: 4px;
                    color: $color-main;
                }
                &>p{
                    @include font-size(14px);
                    color: $color-gree-text;                    
                    line-height: 1.23;
                    margin-top: 10px;
                }   
                &>a{
                    padding: 6px 16px;
                    font-size: 14px;
                    @include font-weight(bold);                
                    line-height: 36px;
                    border: 1px solid $color-main;
                    border-radius: 24px;
                    color: $color-main;
                }    

            }
        }
    }
    .menu-right{
        h4{
            color: $color-gree-text;
            @include font-weight(bold);  
            margin-bottom: 15px;     
        }
        .list-info-user{
            list-style: none;
            margin-left: 0;
            li{
                min-height: 35px;
                .icon-list {
                    width: 25px;
                    height: 25px;
                    background: #e6f4ff;
                    padding: 4px;
                    margin: 0 10px;
                    border-radius: 20px;
                    box-shadow: 1px 1px 8px 2px rgba(4, 96, 178, 0.5);
                    float: left;
                    .icon_passenger_man::before{
                        position: absolute;
                        margin-top: -9px;
                        margin-left: 1px;
                    }
                    .icon_bundle_package:before {
                        position: absolute;
                        margin-top: -10px;
                        margin-left: 1px;
                    }
                    .icon_cards:before {
                        position: absolute;
                        margin-top: -12px;
                        margin-left: 1px;
                    }
                    .icon_door:before {
                        position: absolute;
                        margin-top: -11px;
                        margin-left: 1px;
                    }
                }
            }    
        }
    }
}
.new-card{
    clear: both;
}