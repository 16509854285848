/*------------------------------------*\
    #SPACING
\*------------------------------------*/

//   x
//   -

.sp-stack {
    margin-bottom: $inuit-base-spacing-unit;
}
.sp-stack--small {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.sp-stack--big {
    margin-bottom: $inuit-base-spacing-unit*2;
}

//   -
//   x

.sp-offset {
    margin-top: $inuit-base-spacing-unit;
}
.sp-offset--small {
    margin-top: $inuit-base-spacing-unit--small;
}
.sp-offset--big {
    margin-top: $inuit-base-spacing-unit*2;
}

// - - -
// - x -
// - - -

.sp-inset {
    padding: $inuit-base-spacing-unit;
}
.sp-inset--small {
    padding: $inuit-base-spacing-unit--small;
}
.sp-inset--tiny {
    padding: halve($inuit-base-spacing-unit--small);
}

//   -
//   x
//   -

.sp-vinset {
    padding-top: $inuit-base-spacing-unit;
    padding-bottom: $inuit-base-spacing-unit;
}
.sp-vinset--small {
    padding-top: $inuit-base-spacing-unit--small;
    padding-bottom: $inuit-base-spacing-unit--small;
}
.sp-vinset--tiny {
    padding-top: $inuit-base-spacing-unit--small/2;
    padding-bottom: $inuit-base-spacing-unit--small/2;
}

// | - x - |

.sp-hinset {
    padding-right: $inuit-base-spacing-unit;
    padding-left: $inuit-base-spacing-unit;
}
.sp-hinset--small {
    padding-right: $inuit-base-spacing-unit--small;
    padding-left: $inuit-base-spacing-unit--small;
}

// - | x

.sp-inline {
    margin-left: $inuit-base-spacing-unit--small;
}