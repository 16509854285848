///*------------------------------------*\
//    #LOADING
//\*------------------------------------*/


/********** GLOBAL OVERLAY **********/
.overlay {
    background-color: rgba(255,255,255,.65);
    display: table;
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: $z-index-loading-overlay;
}
.overlay--inDiv {
    position: relative;
}
.overlay-center {
    color: $color-loading-main;
    display: table-cell;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    vertical-align: middle;
}


/********** Spin Big **********/
.spinOverlay {
    height: 128px;
    background: url("/assets/common_assets/img/icon/spin.gif") no-repeat center;
    margin-bottom: $inuit-base-spacing-unit;
}
.spinOverlayLoading {
    height: 300px;
    background-image: url('/assets/common_assets/img/icon/spinLoading.gif');
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}
.spin-text {
    height: 30px;
    text-align: center;
    color: $color-main;
}


/********** Spin Gif Small **********/
.circularG {
    position: relative;
    width: 17px;
    height: 17px;
    background: url('/assets/common_assets/img/icon/ajax-loader.gif') no-repeat;
}


/****** Ellipsis points loader ******/
/*.points-loader {
    min-height: 1em; // ensure same height on empty element
    margin-bottom: $inuit-base-spacing-unit;
    line-height: 1em;
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #666;
}*/


/****** Progress bar loader ******/
.progress-bar {
    width: 40%;
    margin: 0 auto;
    position: relative;
}
.progress-bar__container {
    position: absolute;
    top: $inuit-base-spacing-unit;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: $base-round;
    background-color: #f1f1f1;
    overflow: hidden;
}
.progress-bar__bar {
    width: 0;
    height: $inuit-base-spacing-unit;
    background-color: $color-loading-main;
}
.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color:#66c8e2;
    border-radius: 2px;
    margin: .5rem 0 1rem 0;
    overflow: hidden;
}
    .progress .indeterminate {
    background-color: $color-main;
}
.progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}
.mat-loader--200, .mat-loader--400{
    height: 200px;
    width: 200px;
}
.mat-loader--100, .mat-loader--200, .mat-loader--32, .mat-loader--400{
    -webkit-animation: rotate 1.5s linear infinite;
    animation: rotate 1.5s linear infinite;
}
.mat-loader--200>.mat-loader--200__path, .mat-loader--400>.mat-loader--400__path{
    stroke-dasharray: 1,800;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: $color-main;
    -webkit-animation: dash800 1.125s ease-in-out infinite;
    animation: dash800 1.125s ease-in-out infinite;
}
.mat-loader--100>.mat-loader--100__path, .mat-loader--32>.mat-loader--32__path{
    stroke-dasharray: 1,128;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: #005eb8;
    -webkit-animation: dash128 1.125s ease-in-out infinite;
    animation: dash128 1.125s ease-in-out infinite;
}

    @-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}
@keyframes dash800{
    0% {
        stroke-dasharray:1,800;
        stroke-dashoffset:0;
    }
    50% {
        stroke-dasharray:356,800;
        stroke-dashoffset:-140;
    }
    to {
        stroke-dasharray:356,800;
        stroke-dashoffset:-496;
    }
}
@keyframes dash128{
    0% {
        stroke-dasharray: 1,400;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 178,400;
        stroke-dashoffset: -70;
    }
    to {
        stroke-dasharray: 178,400;
        stroke-dashoffset: -248;
    }
}
@-webkit-keyframes dash1600{
    0% {
        stroke-dasharray:1,1600;
        stroke-dashoffset:0
    }
    50% {
        stroke-dasharray:712,1600;
        stroke-dashoffset:-280
    }
    to {
        stroke-dasharray:712,1600;
        stroke-dashoffset:-992
    }
}

.loadingPackage{
    margin-top: 58px;
    height: 100px;
}
