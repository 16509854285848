///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/
@font-face { font-family: "fontPromoSearchAgency";src: url("/assets/common_assets/font/LEDCalculator.ttf"); }


.search__header {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        padding-top: $inuit-base-spacing-unit;
    }
}
.search__title {
    float: left;
    margin-bottom: 0;
    font-size: 30px;
    font-size: 1.875rem;
    color: $color-gree-text;
    @include media-query(palm) {
        display: block;
        float: none;
        margin-bottom: $inuit-base-spacing-unit;
        text-align: center;
        color: $color-brand;
        @include font-size(28px);
    }
}

// .search__engine {
//     .page__body--search & {
//         background-color: $color-highlight;
//         padding: 0;
//     }
// }

.search__title__icon {
    color: $color-title-icon;
    font-size: quadruple($inuit-base-font-size);
    display: none;
}
.search__btn-group {
    float: right;
    display: table;
    @include font-size(15px);
    @include media-query(palm) {
        float: none;
        display: table;
        margin: 0 auto;
    }
}
.search__btn-group_blog {
    margin: 0 auto;
    display: table;
    font-size: 10px;
    line-height: 1.28571;
     @include media-query(palm) {
         @include font-size(14px);
    }
}
.search__btn-group > .btn {
    font-weight: 300;
    @include media-query(small) {
        font-size: 13px;
    }
    @media screen and (max-width:320px){
        font-size: 11px;
    }
}


// - + Buttons for passengers
.search__btn-increment button {
    border-radius: 40px; //Very hight to get a circle
    margin-left: $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small;
    font-weight: 800;
}
// - + Buttons for passengers
.search__btn-increment-square button {
    margin-left: $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small;
}
.search__btn-increment span {
    display: block;
    width: $inuit-base-spacing-unit;
}


.search__row-main {
    margin-bottom: 0;
}


// Search options
.search__option {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    &.is-disabled {
        display: none;
    }
    &.link-span {
        @include font-weight(bold);
    }
}
.search__option__label {
    line-height: $inuit-base-spacing-unit*2;
}


// Instance creator cta
.search__link-add-multi {
    @include media-query(lap-and-up) {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

.search__picker {
    position: absolute;
    width: 100%;
    padding: ($inuit-base-spacing-unit--small/2) $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        width: 200%;
    }
}

// Header search engine
.search__light {
    @include media-query(palm) {
        margin-top: 0;
    }
    @include media-query(lap) {
        padding-bottom: 92px;
        padding-bottom: 2vh;
        padding-top: 92px;
        padding-top: 2vh;
    }
    @include media-query(desk) {
        margin-top: 35px;
        padding-bottom: 35px;
    }
    @include media-query(lap-and-up) {
        .page__body--front & {
            padding-top: 100px;
        }
    }
}
.search__light--box {
    padding: 10px $inuit-base-spacing-unit--small + 3px;
    border: 0 solid transparent;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.3);
    @include media-query(lap-and-up) {
        /*margin: 100px 0;*/
        padding: 10px 20px;
        background-color: $color-highlight;
        & .btn-group > .btn--inactive {
            color: $color-main;
            background-color: transparent;
            padding: 10px;
        }
        .page__body--front & {
            margin-top: 0;
        }
        & .link-span {
            font-weight: 500;
        }
        & .search__option {
            color: $color-gree-text;
            background-color: rgba(255,255,255,.1);
        }
        & .search__option__label {
            color: $color-gree-text;
        }
        & .label-top {
            color: $color-gree-text;
        }
        & .label--error {
            color: rgba(255,255,255,.9) !important;
            background-color: rgba($color-label-error, .3);
            padding: 0 5px;
            text-shadow: 1px 1px rgba(0,0,0,.8);
            box-shadow: 0 2px 4px rgba(3,3,3,.3);
            border-top: 1px solid rgba($color-label-error, .7);
            border-bottom: 2px solid rgba($color-label-error, .7);
            margin-top: 2px;
        }
    }
}

// Search datepicker
.search-shared__datepicker-container {
    @extend %picker;
    display: none;
    position: absolute;
    padding: 9px 9px 0 9px;
    width: 820px;
    margin-top: 24px;
    @include media-query(lap) {
        width: calc(100% - 20px);
        max-width: 420px;
        padding-bottom: $inuit-base-spacing-unit--small;
    }
    @include media-query(palm) {
        left: 0 !important;
        width: 100%;
        max-width: 420px;
        padding-top: $inuit-base-spacing-unit;
        padding-bottom: $inuit-base-spacing-unit;
    }
}
.search-shared_datepicker-arrow-top {
    display: none;
    position: absolute;
    left: 30px;
    bottom: -15px;
    height: 20px;
    z-index: $z-index-datepicker-arrow;
}

.dropbtn {
    background-color: rgba(0, 155, 248, 0.01);
    color: $color-gree-text;
    //padding: 16px;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 2px 10px 2px 5px;
    min-width: 5.5em;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #b5b5b5;
}

.imgpos {
    vertical-align: bottom;
    float: left;
    max-width: 15px;
    border-radius: 40px;
    margin-right: 5px;
}

.mainimgpos {
    img {
        width: 30px;
    }
}

.mobile-dropbtn {
    .coinflag-text {
        display: none;
    }
    img {
      max-width: 30px;
      width: 30px;
      float: right;
      margin-right: 20px;
      margin-top: 5px;
    }
}

.imgpos-big {
    vertical-align: bottom;
    float: left;
    width: 80px;
}

.coinList {
    position: relative;
    display: inline-block;
}
.coinListContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 21;
    @include media-query(palm) {
        right: 10px;
        width: 90px;
        top: 25px;
    }
}
.coinListContent a {
    color: #000;
    //padding: 12px 16px;
    text-decoration: none;
    display: block;
    padding: 10px 10px 10px 5px;
    @include media-query(palm) {
        padding: 6px 10px 6px 5px;
    }
}
.coinListContent a:hover {background-color: #A4A4A4}

.show {display:block;}

.spiner-none{
    display: none !important;
}

.search-promoSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;
    font-family: Arial Black;
    @include media-query(palm) {
        display: block;
    }
}

.search-promo-traffic-light{
    width: 25%;
    height: 20%;
    background-color: #0359c5;
    border-radius: 50%;
    box-shadow: 5px 8px 10px 10px #0359c5;
    @include media-query(palm) {
        box-shadow: 12px 12px 7px 15px #0359c5;
    }
}
.search-contPromoSearch {
   width: 70%;
   background: #192027;
   padding: 1% 3%;
   color: rgb(2, 103, 230);
   @include media-query(palm) {
       width: 80%;
       margin-left:10%;
   }
}

.search-contIcon {
    width: 5%;
    padding: 6px;
    background: #0267e6;
    border-radius: 10px;
    margin-right: 8px;
    @include media-query(palm) {
        width: 20%;
        background: #f8f9fb00;
        border: 1px solid;
    }
}

.search-contTitle {
   width: 80%;
   display: inline-grid;
   font-size: 22px;
}

.search-contIconTitel {
   display: flex;
}

#titelpromo2 {
   font-size: 11px;
   font-family: Arial;
}

.search-contPromoBody {
   display: block;
   width: 100%;
   border: 3px solid #000;
   margin-top: 5px;
}

.search-contTitlePromoBody {
   display: flex;
   font-size: 15px;
   font-weight: bold;
   width: 100%;
}

.search-titlePromoBody {
   min-width: 19%;
   padding: 1% 4%;
   text-align: center;
}

.search-contBodyPromoBody {
   display: flex;
   width: 100%;
   background: #000;
   text-align: center;
   @include media-query(palm) {
        display: block;
   }
}

.search-contBodyPromoBody:hover {
    text-shadow: 0 0 0.2em #ffffff;
}

.search-bodyPromo {
   width: auto;
   padding: 5px;
   font-family: "fontPromoSearchAgency";
   color: #d2a212;
   display: flex;
   font-size: 100;
   @include media-query(palm) {
        min-width: 100%;
        font-size: 30px;
        text-align: center;
   }
}

.search-bodyPromo span {
    padding: 2px;
    background: rgba(66, 66, 66, 0.72);
    margin: 1px;
    display: block;
    width: 17px;
    @include media-query(palm) {
        width: 100%;
    }
}

.search-bntPromo a {
    color: #33cc39;
    display: flex;
    width: 100%;
}

#contDestino {
   width: 45%;
   @include media-query(palm) {
        width: auto;
        font-size: 100%;
   }
}

.search-contPromoBlockMovile {
    display: none;
    @include media-query(palm) {
        display:block;
    }
}

.search-contBockBodyPromo{
    display: block;
    width: 20%;
    @include media-query(palm) {
        width: 100%;
    }
}

.search-contPromoTitleMovile {
    @include media-query(palm) {
        width: 80%;
        margin: 3% 0 5% 10%;
    }
}

.search-contTitleMovile {
    @include media-query(palm) {
         color:rgb(2, 103, 230);
         font-size:70%;
         margin: 0;
    }
}

.col_map{
    width: 80% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
    @media screen and (min-width:720px) and (max-width:1023px){
        width: 100% !important;
    }
}

.col_filter{
    width: 20% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
    @media screen and (min-width:720px) and (max-width:1023px){
        width: 100% !important;
    }

}

#slider-explore{
    width: 500%;
    @media screen and (max-width:1023px) {
        width: 100% !important;
    }
}

#mapModal{
    margin-right:20% !important;
    left: 350px !important;
    @include media-query(palm) {
        margin-right: 0% !important;
        left: 0px !important;
    }
}

.search__light-explore{
    margin-top: -3% !important;
    padding-top: 3%;
}

.continent_explore_check {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 10px;
    color: #080400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default checkbox */
.continent_explore_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.continent_explore_check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.continent_explore_check input:checked ~ .checkmark {
    background-color:$color-main;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.continent_explore_check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.continent_explore_check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search__col_explore_flight_city{
    width: 30% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.search_col_flight_explore{
    width: 25% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.col-flight-explore-button{
    width: 20% !important;
    @media screen and (max-width:1023px) {
        width: 100% !important;
    }
}

.explore-btn{
    margin-bottom: 10px;
    display:none;
    @include media-query(palm) {
        display:table;
    }
}

.info-search-explore {
  margin-top: 10px;
  h5 {
    margin: 5px 0px;
  }
}
.content-alert-emergency{
    background: #ffb822;
    padding: 10px 10px 1px;
    position: initial;
    width: 103.2%;
    top: 10em;
    margin-left: -18px !important;
    @include media-query(palm){
        position: inherit;
        width: 100%;
        margin-left: 0 !important;
    }
    @media screen and(min-width:722px) and (max-width:1023px){
        width: 105.2%;
    }
    @media screen and(min-width:1023px) and (max-width:1115px){
        width: 103.8%;
    }
    p{
        padding: 4px 0 0 16px;
    }
}
@include media-query(desk){
    .date-1-insure .label-top{
        display: none;
    }
    .date-1-insure .ui-widget-content{
        margin-bottom: 15px;
    }
}
.spacing-search{
    margin: 35px 0 35px !important;
    h1{
        color: $color-gree-text;
        @include font-weight(bold);
        margin-bottom: 20px;
        padding-top: 15px;
    }
}
.input-country{
    padding-right: 10px;
}
.margin-input-documen{
    margin-bottom: 10px;
}
.js-passangerTotal{
    padding-top: 20px;
}