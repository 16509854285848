///*------------------------------------*\
//    #PAGE-FOOT
//\*------------------------------------*/
.page-foot__us * {
    color: $color-page-foot-text;
}
.page-foot__us a {
    @include link-quiet();
    color: $color-gree-text;
    @include font-size(13px);
}
.page-foot__us li {
    list-style-image: url(/assets/common_assets/img/footer/bullet.gif);
}
.page-foot__us__simple {
    @include span(1 of 4);
    @include media-query(portable) {
        @include span(1 of 3);
    }
}
.page-foot__us__simple ul {
    border-right: 1px solid $color-page-foot-text;
}
.page-foot__us__double {
    @include span(2 of 4 last);
    @include media-query(portable) {
        @include span(2 of 3);
    }
}
.page-foot__us__double div:first-of-type {
    @include span(1 of 2);
}
.page-foot__us__double  div:last-of-type {
    @include span(1 of 2);
}
.page-foot__social {
    color: $color-gree-text;
    text-align: left;
    padding-top: $inuit-base-spacing-unit;
    @include media-query(palm){
        text-align: center;
    }
}
.page-foot_title_footer{
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: 800;
    text-align: center;
}
.page-foot__social__icon {
    @include link-quiet();
    padding: 6.5px 7.6px 4px;
}
.page-foot__social {
    border-top: 1px solid #B5B5B5;
    border-bottom: 1px solid #B5B5B5;
    padding: 39px 0 42px;
}
.page-foot__content-social {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include media-query (desk) {
        width: 15%;
    }
    @include media-query (lap) {
        width: 25%;
    }
    .page-foot_link-social {
        border-radius: 26px;
        background-color: $color-main;
        i {
            font-size: 13px;
            &::before {
                color: white;
                vertical-align: super;
            }
        }
    }
    @include media-query (palm){
        text-align: center;
    }
}

.foot_location {
    background-image: url(/assets/common_assets/img/footer/location.svg);
}
.foot_message {
    background-image: url(/assets/common_assets/img/footer/message.svg);
    background-position-y: 8px;
}
.foot_phone {
    background-image: url(/assets/common_assets/img/footer/phone.svg);
}
.foot_whatsapp {
    background-image: url(/assets/common_assets/img/footer/whatsapp.svg);
}
.foot_question {
    background-image: url(/assets/common_assets/img/footer/question.svg);
}
.foot_book {
    background-image: url(/assets/common_assets/img/footer/book.svg);
}
.foot_company {
    background-image: url(/assets/common_assets/img/footer/company.svg);
}
.foot_certification {
    background-image: url(/assets/common_assets/img/footer/certification.svg);
}
.page-foot__last {
    margin-bottom: $inuit-base-spacing-unit;
    padding-top: $inuit-base-spacing-unit;
}
.page-foot_info-content{
    padding: 39px 0 42px;
    @include media-query (palm) {
        padding: 32px 0 32px;
    }
}
.page-foot__legal {
    padding-right: $inuit-base-spacing-unit;
    @include font-size(13px);
    a{
        color: $color-gree-text;
    }
}
.page-foot__p2p {
    margin-bottom: $inuit-base-spacing-unit;
    padding: 0;
    text-align: center;
    /*img {margin: 50px auto 0;}*/
}
.page-foot_content-legal{
    display: flex !important;
    justify-content: space-between;
    @include media-query (palm) {
        flex-direction: column;
    }
    @include media-query (lap) {
        justify-content: flex-start;
    }
}
.page-foot__legal {
    width: 33.33% !important;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 9px !important;
    line-height: 13.5px;
    @include media-query (portable) {
        display: none !important;
    }
}
.page-foot__contact {
    width: 43.333% !important;
    margin-top: -8px;
    ul {
        list-style: none;
        margin-left: 7.25rem;
        @include media-query (portable) {
            margin-left: 0;
        }
    }
    @include media-query (palm) {
        width: 100% !important;
        text-align: center;
        ul{
            margin-left: 0;
        }
        border-bottom: 1px solid #dddddd;
    }
    @include media-query (lap) {
        width: 50% !important;
    }
}
.page-foot_contact-info{
    width: 80%;
    margin: 0 0 0 auto;
    @include media-query (palm) {
        width: 100% !important;
        padding-bottom: 32px;
        padding-left: 10px;
    }
}
.page-foot_image{
    width: 23.3333% !important;
}
.foot_images-legal {
    background-repeat: no-repeat;
    background-position-y: 5px;
    line-height: 26px;
    text-align: start;
}

.foot-contact__info {
    margin-left: 30px;
    font-size: 10px;
    color: black;
}
.place-to_pay{
    margin:50px auto 0
}
.place-to_pay-2{
    margin:16px auto 0
}
.content-indus-cam {
    display: flex;
    justify-content: space-between;
    margin-top:0px;
    @include media-query (palm) {
        justify-content: space-around;
    }
}
.content-img__foot {
    width: 250px !important;
    margin-left: 40px;
    @media screen and (min-width:1024px) and (max-width:1195px) {
        width: 22% !important;
        margin-left: 0px;
    }
    @include media-query (palm) {
        width: 100% !important;
        margin: 25px auto 0;
    }
    @include media-query (lap) {
        width: 50% !important;
        margin-left: 80px;
        margin-right: 60px;
    }
}