/*------------------------------------*\
    #FORMS
\*------------------------------------*/


.text-input {
    &.is-hightlight {
        border-color: $color-form-hightlight;
        box-shadow: inset 0 0 2px 2px rgba(0,0,0,.06);
    }
    &.is-readonly {
        background-color: $color-form-readonly;
    }
    &:not(.no-focus):active,
        &:not(.no-focus):focus,
        &.is-active {
        box-shadow: 0 0 4px $color-form-underline;
    }
}
input.text-input {
    line-height: normal; //xbrowser placeholder position
    background-color: $color-head-gree;
    border: 0px solid $color-head-gree;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 60px;
    border-radius: 2px;
    color: $color-gree-text;
}
.text-input--underline {
    width: 100%;
    padding-left: 2px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: 2px solid rgb(238, 238, 238);
    color: $color-form-underline;
    background-color: rgba(0,0,0,0);
    transition: color .2s, border-bottom .2s;
    outline: 0;
    @include font-size( 20px);
    @include font-weight(bold);
    &:active,
    &:focus {
        border-bottom-color: darken($color-form-underline, 10%);
    }
    @include attention() {
        color: darken($color-form-underline, 10%);
    }
}

.text-input--inline {
    @extend .text-input--underline;
    display: inline;
    max-width: 4em;
    padding: 0;
    text-align: center;
    line-height: 1;
    font-size: 1rem;
    margin: -2px $inuit-base-spacing-unit--small/2 0;
    border-color: rgba($gray-base, .09);
}

.text-input--no-upper-border{
    margin-top: -1px;
}

.text-input--no-border {
    background-color: rgba(0,0,0,0) !important;
    border: 0;
    padding-left: 0;
}
.main__newsletter-block {
    min-height: 74px;
    padding-top: 35px;
}
.boletinForm-email{
    display: flex;
    justify-content: center;
    @include media-query(palm) {
        display: block;
        padding: 0 25px;
    }
}
.content-policy-disclaimer {
    color: #565656;
    font-size: 0.65em;
    padding-bottom: 2px;
    margin-top: 35px;
    label {
        font-size: 12px;
    }
    @include media-query(palm) {
        width: 100%;
        padding-right: 10%;
        padding-left: 10%;
        float: left;
    }
}
.btn-register {
    margin-top: 32px;
}
.form-group-nombre, .form-group-email{
    height: 60px;
    width: 30%;
    float: left;
    @include media-query(palm) {
        width: 100%;
        margin:32px auto 8px;
        float: initial;
    }
    @include media-query(lap){
        width: 40%;
    }
}
.form-group-email{
    margin-left: 23px;
    @include media-query (palm){
       margin-left: auto;
    }
}
.boletinForm-butoom{
    width: 29%;
    margin: 0 auto;
    @include media-query(palm) {
        width: 80%;
    }
}
.datos-boletin{
    color:gray;
    font-weight: 600;
}
#js-newsletterSubscribe{
    padding: 34px;
    margin-bottom: 22px;
    @include media-query(palm){
        padding: 0;
    }
}
.boletinForm-title{
    margin-bottom: 35px;
    h3{
        color: $color-gree-text;
        @include font-weight(bold);
    }
}
#newsletter_form_firstname, #newsletter_form_email{
    margin-top: 5px;
}
.boletin-ofertas{
    border-bottom: 1px solid;
}
.conditions-boletin{
    color: gray;
}
.divider{
    border-bottom: 1px solid rgba(68, 68, 68, 0.3);
    padding-bottom: 20px;
    margin-bottom:20px;
}
  