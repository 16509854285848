.smartbanner-show {
    margin-top: 80px;
}

.smartbanner-show .smartbanner {
    display: block;
}

/** Default **/
.smartbanner {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-family: 'Helvetica Neue', sans-serif;
    background: #f4f4f4;
    z-index: 9998;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    -webkit-text-size-adjust: none;
}

.smartbanner-container {
    margin: 0 auto;
    white-space: nowrap;
}

.smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-family: 'ArialRoundedMTBold', Arial;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-icon {
    display: inline-block;
    vertical-align: middle;
    width: 57px;
    height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px;
}

.smartbanner-info {
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    font-size: 11px;
    line-height: 1.2em;
    font-weight: bold;
}

.smartbanner-title {
    font-size:13px;
    line-height: 18px;
}

.smartbanner-button {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: $color-main;
    text-transform: uppercase;
    text-decoration: none;
    // text-shadow: 0 1px 0 rgba(255, 255, 255, .8);
}

.smartbanner-button:active, .smartbanner-button:hover {
    color: darken($color-main, 8%);
}

.smartbanner-button-text {
}

.smartbanner-button-text:active,
.smartbanner-button-text:hover {
}

/** iOS **/
.smartbanner-ios {
    background: #f4f4f4;
    background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
    line-height: 80px;
}

.smartbanner-ios .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #888;
    //text-shadow: 0 1px 0 white;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-ios .smartbanner-icon {
    background: rgba(0,0,0,.4);
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    background-color: white;
}

.smartbanner-ios .smartbanner-info {
    color: #6a6a6a;
    //text-shadow: 0 1px 0 rgba(255, 255, 255, .8);
}

.smartbanner-ios .smartbanner-title {
    color:#4d4d4d;
    font-weight: bold;
}

.smartbanner-ios .smartbanner-button {
    padding: 0 10px;
    min-width: 10%;
    //color: #6a6a6a;
    //background: #efefef;
    // background: linear-gradient(to bottom, #efefef, #dcdcdc);
    // border-radius: 3px;
    // box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 rgba(255, 255, 255, .6), 0 2px 0 rgba(255, 255, 255, .7) inset;
}

/*.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover {
        background: #dcdcdc;
        background: linear-gradient(to bottom, #dcdcdc, #efefef);
}*/

.smartbanner-ios .smartbanner-button-text {
}

.smartbanner-ios .smartbanner-button-text:active,
.smartbanner-ios .smartbanner-button-text:hover {
}