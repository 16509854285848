///*------------------------------------*\
//    #DATEPICKER
//\*------------------------------------*/

// DATEPICKER STYLE - default overlay //----------------------------------------

.ui-datepicker {
    width : 300px;
    padding: 0 .2em .2em;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
}

.ui-datepicker .ui-datepicker-header {
    margin: 0 -.2em;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    background-color: #eee;
    padding: 0 $inuit-base-spacing-unit;
}
/*.ui-datepicker .ui-datepicker .ui-datepicker .ui-datepicker-title {
    margin:0 2.3em;
    line-height:1.8em;
    text-align:center
}*/
.ui-datepicker .ui-datepicker-title select {
    font-size:1em;
    margin:10px 0;
    line-height:1.2em;
    padding:5px 10px;
    font-weight: 600;
    cursor:pointer;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width:49.9%
}
.ui-datepicker-buttonpane {
    background-image:none;
    margin:.7em 0 0 0;
    padding:0 .2em;
    border-left:0;
    border-right:0;
    border-bottom:0
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float:right;
    margin:.5em .2em .4em;
    cursor:pointer;
    padding:.2em .6em .3em .6em;
    width:auto;
    overflow:visible
}
.ui-datepicker .ui-datepicker-prev, 
.ui-datepicker .ui-datepicker-next {
    display: none;
}
.ui-datepicker-calendar thead tr th {
    padding: 0 0 .3em;
    font-size: 15px;
}
.ui-datepicker-calendar tbody tr td {
    font-size: 15px;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float:left
}
.ui-datepicker .ui-datepicker-calendar {
    width:95%;
    margin: 0 auto .4em;
}
.ui-datepicker.ui-datepicker-multi {
    width:auto
}
.ui-datepicker-multi .ui-datepicker-group {
    float:left
}
.ui-datepicker-multi-2 .ui-datepicker-group {
    width:50%;
    @include media-query(portable) {
        width:100%;
    }
}
.ui-datepicker-multi-3 .ui-datepicker-group {
    width:33.3%
}
.ui-datepicker-multi-4 .ui-datepicker-group {
    width:25%
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width:0
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear:left
}
.ui-datepicker-row-break {
    clear:both;
    width:100%;
    font-size:0
}
.ui-datepicker-rtl {
    direction:rtl
}
.ui-datepicker-rtl .ui-datepicker-prev {
    right:2px;
    left:auto
}
.ui-datepicker-rtl .ui-datepicker-rtl .ui-datepicker-prev:hover {
    right:1px;
    left:auto
}
.ui-datepicker-rtl .ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear:right
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float:left
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
    float:right
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width:0;
    border-left-width:1px
}
.ui-corner-all,.ui-corner-top,.ui-corner-left,.ui-corner-tl {
    border-top-left-radius:2px
}
.ui-corner-all,.ui-corner-top,.ui-corner-right,.ui-corner-tr {
    border-top-right-radius:2px
}
.ui-corner-all,.ui-corner-bottom,.ui-corner-left,.ui-corner-bl {
    border-bottom-left-radius:2px
}
.ui-corner-all,.ui-corner-bottom,.ui-corner-right,.ui-corner-br {
    border-bottom-right-radius:2px
}