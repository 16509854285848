///*------------------------------------*\
//    #STATS - DASHBOARD
//\*------------------------------------*/

.stats-dashboard {
    position: relative;
    text-align: center;
    
    h2 {
        color: #f00;

    }
    
    h3 {
        color: #000;
        width: 100%;
        border-bottom: 2px solid #eee;
    }
    
    .master {
        
        .panel {
            display: inline-block;
            border: 1px #eff1f7 solid;
            border-radius: 5px;
            margin: 1px;
            width: 250px;
            height: 100px;
            vertical-align: top;
            
            h4{
                color: #06C;
            }
            .value {
                color: #000080;
                font-family: monospace, serif;
                font-size: 35px;
            }
        }

    }
}
