/*------------------------------------*\
    #TOOLTIPS
\*------------------------------------*/

// Internal variable
$arrow-height: 8px;


.tooltips {
    position: relative;
    display: inline;
}
.tooltips-content {
    background: #000;
    border-radius: $base-round;
    color: #fff;
    left: 50%;
    padding: $inuit-base-spacing-unit--tiny;
    position: absolute;
    text-align: center;
    top: 0;
    visibility: hidden;
    width: 150px;
    transform: translateX(-50%) translateY(-100%) translateY(-10px); // don't you dare nest a calc( in here... for IE's sake
    @include font-size(13px);
    @include font-weight(regular);
}
.tooltips-content--medium {
    @extend .tooltips-content;
    width: 100px;
}
.tootips-offers {
    width: 300px;
}
.tooltips-content--200 {
    @extend .tooltips-content;
    width: 200px;
}
.tooltips-content--full {
    @extend .tooltips-content;
    width: 100%;
}
.tooltips-content--left {
    @extend .tooltips-content;
    @include media-query(palm) {
        left: 100%;
        transform: translateX(-100%) translateY(-100%) translateY(-10px);
    }
}
.tooltips-content:after {
    border-top: $arrow-height solid #000;
    border-right: $arrow-height solid transparent;
    border-left: $arrow-height solid transparent;
    content: '';
    left: 50%;
    margin-left: -$arrow-height;
    position: absolute;
    top: 100%;
    width: 0; height: 0;
}
.tooltips-content--left:after {
    @extend .tooltips-content:after;
    @include media-query(palm) {
        left: unset;
        right: 4px;
        margin-left: 0;
    }
}
.tooltips-content.downward {
    top: 100%;
    transform: translate(-50%, 10px);
    &:after {
        top: -$arrow-height;
        border-top: 0;
        border-bottom: $arrow-height solid #000;
    }
}
.tooltips-trigger:hover ~ .tooltips-content,
.tooltips-trigger:hover .tooltips-content,
.tooltips:hover .tooltips-content {
    opacity: .85;
    visibility: visible;
    z-index: $z-index-tooltips;
}

.tooltips-hide:hover ~ .tooltips-content,
.tooltips-hide:hover .tooltips-content {
    display: none;
}


.point-bestPrice {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #77da79;
}