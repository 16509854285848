///*------------------------------------*\
//    #DISPO-PACKAGE
//\*------------------------------------*/

// Internal variable
$height-big:     240px;
$height-small:   140px;

.dispo-experience__col-image {
    @include span(1 of 3);
}
.dispo-experience__image {
    width: 100%;
    height: $height-big;
    background: center center no-repeat;
    background-size: cover;
    border-radius: 10px;
}
.dispo-experience__col-content {
    @include span(2 of 3);
}
.dispo-experience__title {
    line-height: 1.4;
    color: $color-gree-text !important;
    line-height: inherit;
    @include font-weight(bold);
    @include media-query(palm){
        margin: 14px 0;
    }
}
.dispo-experience__detail {
    @include font-size(14px);
    margin-top: 5px;
}
.dispo-experience__price {
    @include font-size(20px);
}
.detail-avail-expe-info{
    @include media-query(desk){
        min-height: 90px;
    }
}
.info-dates-availa-expe{
    margin: 0 0 8px;
}

.dispo-experience__cautions {
    @include span(full);
}    
.dispo-experience__cautions-content {
    padding: $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small 0;
    margin-bottom: $inuit-base-spacing-unit--small;
    background-color: $gray-plain-light;
    box-shadow: 2px 2px #eee;
}
.dispo-experience__cautions-loader {
    min-height: 200px;
}
.dispo-experience_ribbon {
  position: absolute;
  right: -4px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.dispo-experience_ribbon span {
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#1e5799 0%, #34bac9 57%);  
  position: absolute;
  top: 19px;
  right: -21px;
}
.dispo-experience_ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #34bac9;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #34bac9;
}
.dispo-experience_ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #34bac9;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #34bac9;
}