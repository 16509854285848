///*------------------------------------*\
//    #DETAIL-CRUISE
//\*------------------------------------*/ 

$cruise-ititnerary-height: 350px;
$cruise-ititnerary-height--portable: 290px;

.detail-cruise__vendor {    
    float: left;
    display: inline-block;
    margin-left: $inuit-base-spacing-unit;
    @include media-query(palm) {
        @include span(full);
        margin-left: 0;
    }
    & .details-ticket {
        padding-left: $inuit-base-spacing-unit--small;
        width: 100%;
    }
}

.detail-cruise__title {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.detail-cruise__title--sub {
    @extend .detail-cruise__title--lighter;
    @include font-weight(bold);    
}
.detail-cruise__title--light {
    color: #333;
}
.detail-cruise__title--lighter {
    color: #666;
}

.detail-cruise__image {
    width: 38%;
    float: left;
    position: relative;
    @include media-query(palm) {
        width: 100%;
    }
}
.detail-cruise__image .ribbon {
    top: 0;
    left: $inuit-base-spacing-unit--small;
    @include media-query(palm){
        top: -$inuit-base-spacing-unit--small;
    }
}
.detail-cruise__carousel__image {
    max-height: 243px;
    background: no-repeat center center;
    border-radius: $base-round;
    background-size: cover;
    @include media-query(palm) { 
        width: 100%;
    }
}

.detail-cruise__additional-info .btn-link {
    @include media-query(palm) {
        width: 100%;
        margin-bottom: $inuit-base-spacing-unit--small;
        & .btn {
            width: 100%;
        }
    }
}


.detail-cruise__info-row {
    padding-top: $inuit-base-spacing-unit;
    border-top: 1px dashed $color-frame-border;
}

// Itinerary info
.detail-itinerary-map {
    @include span(1 of 2);
    @include media-query(portable) {
        @include span(full); 
    }
}
.detail-itinerary-info {
    @include span(1 of 2);
    max-height: $cruise-ititnerary-height;
    overflow-y: auto;
    @include media-query(portable) {
        @include span(full);
        max-height: $cruise-ititnerary-height--portable;
    }
}
.detail-itinerary-info table {
    @include media-query(portable) {
        font-size: 13px;
    }
}
.hidden-destination-info {
    display: none;
}

// Cabin options
.detail-cruise__info-row .frame-group {
    margin-bottom: $inuit-base-spacing-unit + $inuit-base-spacing-unit--small;
    & .frame {
        @include media-query(palm) {
            padding-top: $inuit-base-spacing-unit;
        }
    }
}
.detail-cruise__options {
    @include media-query(palm) {
        margin: 0 -$inuit-base-spacing-unit--small;
    }
}
.detail-cruise__cabin {
    width: 100%;
    overflow: auto;
    padding: 0 0 $inuit-base-spacing-unit--small 0;
}
.cabin-image {
    @include span(7 of 25);
    padding-left: 0;
    @include media-query(palm) {
        @include span(full);
    }
    & img {
        border-radius: $base-round;
    }
}
.cabin-detail-text {
    @include span(18 of 25);
    @include media-query(palm) {
        @include span(full);
    }
    & p {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.cabin-detail-title {
    @include media-query(palm) {
        margin: $inuit-base-spacing-unit--small 0;
        line-height: 1.2;    
    }
}
.detail-cruise__option {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.row-cabin-category {
    padding: $inuit-base-spacing-unit--small;
    border: 1px solid rgba(0,0,0,.2);
    border-bottom-width: 2px;
    border-radius: $base-round;
    color: #fff;
    box-shadow: inset 0 40px 60px -10px rgba(0, 0, 0, .2);
    background-color: $color-main;
}
.col-cabin-category {
    @include span(1 of 4);
}
.col-cabin-title {
    font-weight: bold;
    color: rgba(255,255,255,.5);
}
.col-cabin-content {
    padding-top: 3px;
}
.detail-cruise__submit--palm {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-cruise__submit--lap-and-up {
    padding-top: 3px;
}


// Additional info modals
.detail-cruise__info-modal {
    font-size: .9em;
}
.facilities-content {
    @include span(1 of 3);
    @include media-query(palm) {
        @include span(full);
    }
    & ul li {
        list-style-type: circle;
    }
}


// Itinerary Cruise map section
.map-cruise-container h4 {
    text-align: center;
    margin-bottom: $inuit-base-spacing-unit--small;
    color: $color-main;
}
.map-cruise-container #map {
    height: $cruise-ititnerary-height;
    @include media-query(portable) {
        height: $cruise-ititnerary-height--portable;
    }
    margin-bottom: $inuit-base-spacing-unit;
}
.map-cruise-container .itinerary_flights {
    display: none;
}
.map-cruise-container #map .cruise_marker {
    position: absolute;
    z-index: 999999;
}
.map-cruise-container #map .cruise_marker .counter {
    position: absolute;
    top: 6.5px;
    left: 5.5px;
    width: 13px;
    line-height: 14px;
    text-align: center;
    font-weight: 900;
    font-size: 140%;
    font-family: 'Open Sans';
    color: #fbfbfb;
}
.map-cruise-container #map .cruise_marker .counter img.multiCounts {
    position: absolute;
    top: 2px;
    left: 2px;
}
.map-cruise-container #map .cruise_infoBox {
    padding: 5px 10px 5px 10px;
    font-family: 'Open Sans';
}
.map-cruise-container #map .cruise_infoBox ~ .cruise_infoBox  {
    border-top: 1px dashed #333;
}
.map-cruise-container #map .cruise_infoBox .flight_stopover {
    font-style: italic;
}
.map-cruise-container #map .cruise_infoBox .port_placeName {
    font-weight: 900;
}
// End Itinerary Cruise map section


///*------------------------------------*\
//    #CRUISE RESERVATION
//\*------------------------------------*/ 

.reservation-cruise__content {
    @include span(4 of 6);
    @include media-query(portable) {
        @include span(full);  
    }
}

.detail-cruise__sidebar {
    @include media-query(desk) {
        @include span(2 of 6);
    }
    
    & .detail-cruise__title {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

.detail-cruise__label {
    margin-bottom: $inuit-base-spacing-unit--small;
}

.detail-cruise__rate-price {
    @include span(full);
}
.detail-cruise__rate-detail {
    @include span(full);
    margin-bottom: $inuit-base-spacing-unit;
}

.detail-cruise__rate-table {
    & tr td {
        vertical-align: middle !important;
        &:nth-child(2) {
            padding-right: $inuit-base-spacing-unit--small;
            padding-left: $inuit-base-spacing-unit--small;
        }
    }
}

.reservation-cruise__image {
    position: relative;
    margin-bottom: $inuit-base-spacing-unit;
    & .ribbon {
        top: 0;
    }
}
