///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/


.search__col-train-city {
    @include span(6 of 14);
    @include media-query(lap) {
        @include span(full);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
.search__col-train-date {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}


.search-train__col-room {
    @include span(5 of 7);
    @include media-query(palm) {
        @include span(full);
    }
    & label:not(.label--error) {
        margin-bottom: 0;
    }
    @include media-query(desk) {
        .search__light--box & .search-train__row-room:last-of-type .form-group {
            margin-bottom: 0;
        }
    }
}
.search-train__col-submit {
    font-size: 14px;
    @include span(2 of 7);
    @include media-query(lap) {
        margin-top: $inuit-base-spacing-unit--small;
        @include span(full);
    }
    @include media-query(palm) {
        @include span(full);
    }
    & .search__footer__submit:not(:last-of-type) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.search-train__conditions {
    margin-bottom: 5px;
    & .label-conditions {
        margin-bottom: 0;
        .search__light--box & {
            padding-left: .5rem;
            border-radius: 2px;
            background-color: rgba(255, 255, 255, .1);
        }
    }
}


.search__light--box .search-train__instance-creator {
    @include media-query(desk) {
        margin-top: $inuit-base-spacing-unit;
    }
}


.search-train__col-passenger {
    @include span(3 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
    }
    @include media-query(palm) {
        @include span(full);
    }    
}
.search-train__col-child {
    @include span(2 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
        float: right;
    }
    @include media-query(palm) {
        @include span(1 of 3);
    }
}