///*------------------------------------*\
//    #BUNDLE CONTENT
//\*------------------------------------*/

.content {
    background-color: white;
    border-radius: 5px;
    margin: 15px 0 0 0;
    padding: 15px;
}
.content-col {
    @include span(1 of 2);
    @include media-query(palm) {
        @include span(full);
    }
}
.content-footer {
    margin: 0;
}

.conteng-img {
    float: left;
    background-color: white;
    margin: 0 5px 5px 5px ;
}
.content-con{
    width: 33%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;   
    @include media-query(palm) {
        @include span(full);
    }
}
.content-promo_index {
    width: 24%;
    float: left;
    padding: 20px;
    color: black;
    font-weight: 400;
    margin: 5px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    @include media-query(palm) {
        width: auto;
        margin-left: 9px;
        margin-right: 9px;
    }
}
.content-promo_index h4{
    text-align: center;
    line-height: 1.25;
}

.content-footer {
    margin: 0;
}
.medios-pago {
    display: flex;
    align-items: center;
    justify-content: center;
}
.medios-pago .medios-texto{
    text-transform: uppercase;
    color: #2063cc;
    margin-right: 10px;
}
.medios-pago .titulo01{
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}
.medios-pago .titulo02{
    font-weight: 700;
    font-size: 19px;
    margin: 0;
}
.medios-pago .medios-imagenes {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    padding: 10px 0;
}
.medios-pago .medios-img {
    text-align: center;
    margin: 0 5px;
    padding: 5px;
    border-radius: 4px;
}
.medios-pago .medios-img:hover {
    background: #f5f5f5;
}
.medios-pago .medios-img img{
    height: 32px;
}
.medios-pago .medios-img span {
    font-size: 12px;
    color: #666;
    display: block;
    max-width: 60px;
    margin: 10px auto 0;
}
.conteng-img {
    float: left;
    background-color: white;
    margin: 0 5px 5px 5px ;
}
.content-menu-li-a {
    display: inline-block !important;
}
.content-nav {
    background-color: $color-main;
    position: relative;
}
.content-promo_index h4 {
    text-align: center;
    line-height: 1.25;
}
.content-navbar:hover {
    background-color: $gray-accent-light;
}

.content-navbar,
.content-pagination > li > a,
.content-pagination > li > span {
    background-color: $gray-accent-light;
}

.pagination > li > a:focus,
.content-pagination > li > a:hover,
.content-pagination > li > span:hover,
.content-pagination > li > span:focus {
    color: $gray-accent-light;
    background-color: $gray-accent-light;
    border-color: $gray-accent-light;
}

.revista-horizontes{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    float: left;
    text-align: center;
    margin-top: 20px;
}
.revista-horizontes select{
    width: 26%;
    float: left;
    padding-right: 5%;
    padding-left: 1%;
    margin: 0 1%;
    font-size: .99rem;
    border: 0;
    @include media-query(palm) {
        width: 100%;
    }
}

.horizontes-img {
    margin: 2%;
    float: left;
    width: 21%;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #fff;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .54);
}
.horizontes-img .mascara,.horizontes-img .contenido {
    position: absolute;
    margin: 0 auto;
    width:100%;
    top: 0;
    left: 0;
}
.horizontes-img img {
    width: 100%;
}
.horizontes-img h2 {
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, .9);
    margin: 20px 0 0 0;
}
.horizontes-img p {
    font-size: 12px;
    color: #fff;
    padding: 10px 20px 10px;
    text-align: center;
    margin: 0;

}
.horizontes-img a.link {
    text-decoration: none;
    padding: 7px 14px;
    background: $color-btn-submit;
    border-radius: 5px;
    color: #fff;
    box-shadow: 0 0 1px #000;
}
.horizontes-img a.link:hover {
    box-shadow: 0 0 5px #000;
    background: #990d0d;
}


.horizontes-2 img {
    transition: all .2s ease-in;
}
.horizontes-2 .mascara {
    background-color: rgba(0, 0, 0, .60);
    width: 500px;
    height: 500px;
    padding: 20px;
    opacity: 1;
    transform: translate(300px, 200px) rotate(45deg);
    transition: all .5s ease-in-out;
}
.horizontes-2 h1 {
    font-size: 12px;
    color: $color-btn-submit;
}
.horizontes-2 h2 {
    border-bottom: 1px solid #fff;
    background: transparent;
    margin:40px;
    transform: translate(200px, -200px);
    transition: all .3s ease-in-out;
}
.horizontes-2 p {
    transform: translate(-200px, 200px);
    transition: all .4s ease-in-out;
}
.horizontes-2:hover .mascara {
    opacity:1;
    transform: translate(-96px, -120px) rotate(45deg);
}
.horizontes-2:hover h2 {
    transform: translate(0px,0px);
    transition-delay: .3s;
}
.horizontes-2:hover p {
    transform: translate(0px,0px);
    transition-delay: .4s;
}
@media screen and (min-width: 701px) and (max-width: 900px) {
    .horizontes-img{
        width:29%;
    }}
@media screen and (min-width: 300px) and (max-width: 700px) {
    .horizontes-img{
        width:45%;
    }
    .horizontes-img .contenido {
        margin: 0 auto;
        width: 100%;
    }
    .horizontes-2 h2{
        margin: 15px;
    }
}
@media screen and (min-width: 901px) and (max-width: 1200px) {
    .horizontes-img{
        width:20%;
    }}
@media screen and (max-width: 600px){
    .contenidos {
        width: 100%;
        display: inline-block;
        text-align:justify;
        padding:10px;
    }

    .contenidos img{
        width: 100%!important;
        margin:0!important;
    }
}
.info-aviatur {
    border-top: solid 1px #ccc;
    padding-top: 20px;
}
.aerolineas {
    width: 48%;
    margin: 1%;
    float: left;
    border-style: double;
    border-width: 4px;
    border-color: #ccc;
    box-shadow: 4px 4px 8px #ccc;
    border-radius: 30px 0 20px 0;
}
.aero-info {
    background: #f3f3f3;
    width: 70%;
    float: left;
    padding: 0 0 11px 0;
    border-radius: 0 0 20px 0;
}

.aero-info ul {
    padding: 0 4%;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}
.aero-info li {
    list-style: none;
}

.aero-info h3 {
    background: #dcdcdc;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    padding: 10px;
}
.aerolineas img {
    width: 100%;
    max-width: 200px;
}
.logo-aero {
    width: 30%;
    float: left;
    margin: 12% 0;
}
.aero-web {
    background: url(/source/aerolineas/web.svg);
}
.aero-checkin{
    background: url(/source/aerolineas/check-in.svg);
}
.aero-contrato{
    background: url(/source/aerolineas/contrato.svg);
}
.aero-web, .aero-checkin, .aero-contrato {
    background-size: 16px;
    background-repeat: no-repeat;
    padding: 0 0 0  28px;
    background-position: 0 2px;
}
.aero-web a:hover, .aero-checkin a:hover, .aero-contrato a:hover {
    color: #8c8c8c;
}
.link-aero {
    text-decoration: none;
    padding: 4px 10px;
    background: $color-btn-submit;
    border-radius: 10px 0 10px 0;
    color: #fff!important;
    box-shadow: 0 0 1px #000;
    float: right;
    margin-top: 5%;
    font-weight: 600!important;
}

@media (min-width: 200px) and (max-width: 600px){
    .aerolineas {
        width: 100%;
        margin: 1% 0;
    }
    .aerolineas img {
        width: 100%;
        max-width: 200px;
    }
    .aero-info {
        width: 100%;
        padding: 11px 0;
    }
    .aero-info h3{
        display: none;
    }
    .logo-aero {
        width: 100%;
        text-align: center;
        margin: 5% 0;
    }
}
@media (min-width: 601px) and (max-width: 1000px){
    .aero-info {
        width: 100%;
        padding: 11px 0;
    }
    .logo-aero {
        width: 100%;
        text-align: center;
        margin: 6% 0;
    }
    .aero-info h3{
        display: none;
    }
}

/********css casa cholon*****/

.contenedor-cn {width: 100%;padding: 2% 4%;margin: 0 auto;max-width: 1100px;overflow:auto;background: url(/source/casa-cholon/skater.jpg);}
.contenido-cn {width:100%;background: rgba(255, 255, 255, .9);padding:20px;position:relative;}
.contenido-cn h2 {
    font-family: 'Dosis', Helvetica, Arial, sans-serif;
    color: #009BF8;
    text-align: center;
    border-bottom: double 2px #009BF8;
    font-size: 20px;
}
.contenido-cn img {max-width: 100%;display: block;margin: 0 auto;padding: 20px 0;}
.notas {font-size:10px; color:#333;}


.box{
    position: relative;
    width: 50%;		/* desired width */
    -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%;
    border:#FFF 6px double;
    float:left;
}
.box:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
}
.content-cn{
    color: #FFF;
    font-size: 12px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 85%;
}
.francois {font-family: 'Dosis', Helvetica, Arial, sans-serif; font-size:18px; text-align:center;}
.descript { width:48%; float:right; color:#fff; font-size:13px; text-align:right;}
.descript h3 {font-size:21px;color:#fff;}
.descript h4 {font-size:13px;color:#fff;border: dotted 2px #fff;width:100%;font-size:16px;padding:5px;text-align:center;}
.descript a {color:#0CF; font-weight:bold;}
.precio {background-color: #CC3300;color: #FFF;font-size: 16px;line-height: 17px;padding: 9px;position: absolute;right: 0;text-align: center;top: -52px;width: 165px;}
.precio p {margin:0;}
.precio span {font-size: 17px; font-weight: 600;}
.destacado_cir {width:100%;padding:15px 10px;background-color:rgba(0,0,0,.8);overflow:auto;}
.cita_1 {font-size:26px; font-weight:bold;}
.footer-cn { width:100%; overflow:auto; background-color: rgba(0,0,0,.5); color:#fff;}
.footer-cn .moduletable {
    margin: auto;
    max-width: 1200px;
}
.footer-cn a {color:#fff; text-decoration:none;}
.footer-cn ul li {
    height: auto;
}
.footer-cn .parent {
    float: left;
    font-size: 12px;
    list-style: none outside none;
    margin: 20px 0;
    width: 33%;
}
.footer-cn p {font-size:12px; }
.separator {
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    list-style: none outside none;
    text-transform: uppercase;
}
@media screen and (max-width: 768px) {
    .box, .descript{
        width: 50%;
    }
}
@media screen and (max-width: 700px) {
    .precio {
        position: inherit;
        width: 100%;
        padding: 2px;
    }
}
@media screen and (max-width: 450px) {
    .box, .descript{
        width: 100%;
    }
}
/********fin css casa cholon*****/


.cont-whatsapp {
    background: url(/assets/aviatur_assets/img/icon/whatsapp-aviatur.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
    height: 86px;
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 1090px;
    width: 100%;
}
.tel-whatsapp{
    display:none;
}
.icon-whatsapp {
    background: url(/assets/aviatur_assets/img/custom/icon-whatsapp.png)no-repeat;
    background-size: contain;
    padding-left: 27px;
    background-position: 0;
}
@media screen and (max-width: 700px) {
    .cont-whatsapp {
        display:none;
    }
    .tel-whatsapp{
        display: block;
        text-align: center;
        font-size: 34px;
    }
    .tel-whatsapp a{
        font-weight: 400!important;
        color: #000!important;
    }
}
/***Medios de Pago*****/

.mdp{
    width: 100%;
    float: left;
    text-align: center;
    margin: 10px 0;
}
.mdp-1{
    float: left;
    padding: 10px;
    width: 33%;
}
.mdp-2{
    float: left;
    padding: 10px;
    width: 60%;
}
.mdp-3{
    float: left;
    padding: 10px;
    width: 40%;
}
.mdp-4{
    float: left;
    padding: 10px;
    width: 49%;
}
.mdp-5{
    float: left;
    padding: 10px;
    @include span(1 of 2);
    @include media-query(palm) {
        @include span(full);
    }
}
.mdp-2 img, .mdp-3 img, .mdp-4 img, .mdp-5 img {
    border:1px solid #009bf8;
    padding: 0 5px;
    width: 100%;
}
.horas{
    background: url(/source/medios-de-pago/24h.png);
    background-repeat: no-repeat;
}
.reloj{
    padding: 16px 76px;
    background-position: -50px -1px;
}
.tarjeta{
    padding: 0 25px;
}
@media screen and (max-width: 700px) {
    .reloj{
        padding: 16px 76px;
        background-position: -57px -1px;
        float: right;
        width: 100%;
        max-width: 67%;
    }
}
@media screen and (max-width: 930px) {
    .mdp p {
        line-height: 30px;
    }
}

// .pokerutas {
//     background: url(/source/vuelos-aviatur/pokemon-mundo.jpg) no-repeat;
//     background-size: contain;
// }
// @media screen and (max-width: 550px) {
//     .pokerutas {
//         background: url(/source/vuelos-aviatur/pokemon-mundo-2.jpg) no-repeat;
//     }
// }

/**promociones Aviatur**/
.offer__content {
    width: 380px;
    padding: 0;
    display: inline-block;
    cursor: pointer;
}
.content-promo {
    height: 134px;
    background: #fff;
    border: 1px solid $color-main;
    border-radius: 0 0 $base-round $base-round;
}
.content-promo::before {
    border-right: 130px solid transparent;
    border-left: 130px solid transparent;
    border-top: 20px solid $color-main;
    content: '';
    margin-bottom: 3px;
    display: inline-block;
}
.content-promo p{
    margin:0;
    font-size: 12px;
}
.content-promo em{
    font-size: 33px;
    color: $color-main;
    font-style: normal;
}
.content-promo-img {
    height: 130px;
    border-radius: $base-round $base-round 0 0;
}
.content-promo-img em{
    float: right;
    margin: 10px 16px 0 0;
    text-shadow: -2px 2px 2px #000;
    color: #fff;
    font-size: 30px;
    font-style: normal;
}
.content-promo__price {
    width: 100%;
    float:left;
    font-size: 13px;
    margin-top: 7px;
    padding: 11px 0;
    transition: all 100ms linear;
}
.content-promo__price em {
    font-size: 13px;
    background: $color-main;
    color: #fff;
    border-radius: 100%;
    padding: 1px 6px;
    font-style: normal;
}
.content-promo__descripcion{
    width: 100%;
}

.grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 1em 0 4em;
    list-style: none;
    color: black;
    text-align: center;
    font-family: Verdana, sans-serif;
}
.grid figure {
    position: relative;
    overflow: hidden;
    max-height: 360px;
    height: 250px;
    cursor: pointer;
}
.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}
.grid figure figcaption {
    padding: 2em;
    color: #fff;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    height: 100%;
}
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}
.grid figure h3,
.grid figure p {
    margin: 0;
    color:white;
    letter-spacing: 1px;
}
figure.promo-effect {
    background: #474747;
    width: 100%;
    margin-bottom: 0;
    margin: 0;
}
figure.promo-effect img {
    margin: -5px 0 0 -10px;
    max-width: none;
    width: -webkit-calc(100% + 10px);
    width: calc(100% + 10px);
    opacity: 0.7;
    height: 250px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05,1.05,1);
    transform: scale3d(1.05,1.05,1);
}
figure.promo-effect figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
}
figure.promo-effect p {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 2em;
    text-shadow: -2px 2px 2px #000;
    padding-right: 10px;
    max-width: 220px;
    border-right: 3px solid #fff;
    text-align: right;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}
figure.promo-effect h3 {
    text-align: left;
    font-size: 1.875rem;
    text-shadow: -2px 2px 2px #000;
}
figure.promo-effect:hover img {
    opacity: 0.5;
    -webkit-transform: scale3d(1.3,1.3,1.3);
    transform: scale3d(1.3,1.3,1.3);
}
figure.promo-effect:hover figcaption::before {
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}
figure.promo-effect:hover p {
    opacity: 1;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

/*******Css Destinos******/
.destinos h1,.destinos h2{
    color:#009bf8;
    text-shadow: 1px 1px 3px #fff, 2px 1px 5px #009bf8;
}
.destinos-promo {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    float: left;
    padding: 0 24px;
}
.content-destinos {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}
.clear{
    display:table;
    background:#009bf8;
    width:100%;
}
.link-list li {
    border-top: 1px solid #ddd;
    display: table;
    min-height: 42px;
    width: 100%;
}
.floatl {
    float: left;
    margin: 0;
}
.floatr {
    float: right;
    margin: 0;
    line-height: 42px;
}
.floatl a {
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    display: table-row;
    width: 100%;
    position: relative;
}
.floatr spam {
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    display: table-row;
    width: 100%;
    position: relative;
}
.floatr span {
    font-weight: 600;
    line-height: 42px;
    display: table-row;
}
.sitios-tur-izq {
    width: 100%;
}
.sitios-tur-der {
    width: 100%;
    margin: 0 auto;
}
.link-list {
    margin: 0;
    padding: 0 10px;
}
.clear h3 {
    float: left;
    margin: 0;
    color: #fff;
    padding: 10px 10px;
    font-size: 20px;
}
.clear p {
    float: right;
    margin: 0;
    font-size: 17px;
    padding: 7px 10px;
    bottom: 0;
    line-height: 36px;
    color: #fff;
}
.stripe-line {
    display: block;
    margin: 10px 0;
    height: 4px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    flex: 1;
    background-color: #009bf8;
}
.widget-title h2 {
    display: block;
    margin: 0 10px 0 0;
    font-size: 24px;
    text-transform: uppercase;
}
/**widget clima, destinos*/
.img-area {
    position: relative;
    height: 170px;
    width: 460px;
    color: white;
    margin: 0 auto;
}
.img-area img {
    width: 100%;
    height: 170px;
    z-index: 1;
}
.img-area .img-mask {
    position: absolute;
    width: 100%;
    height: 170px;
    background: rgba(10, 10, 10, 0.83);
    z-index: 2;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
}
.img-area .img-area-front {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 170px;
    top: 0;
    left: 0;
}
.week-forecast {
    list-style: none;
    margin: 0px;
    background-image: url('/assets/aviatur_assets/img/weather/fondo.jpg');
    padding: 0;
    overflow: hidden;
    width: 460px;
    height: 120px;
    margin-bottom: 10px;
    margin: 0 auto;
}
.week-forecast > li {
    float: left;
    width: 20%;
    height: 120px;
}
.week-forecast > li > .inner {
    border: 1px solid rgba(92, 92, 92, 0.77);
    width: 100%;
    height: 100%;
    padding-top: 15px;
    text-align: center;
}
.week-forecast > li > .inner  .climacon{
    text-shadow: 0 3px 1px rgba(0,0,0,0.1);
    font-size: 40px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid rgb(141, 141, 141);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.49);
    margin-bottom: 5px;
}
.week-forecast > li > .inner .climacon.rain.sun {
    padding-top: 2px;
    padding-left: 3px;
}
.week-forecast > li > .inner .climacon.cloud.sun {
    padding-top: 4px;
    padding-left: 2px;
}
.week-day {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 300;
    font-size: 13px;
    color: black;
}
.week-day-temperature {
    color: black;
    position: relative;
    font-size: 13px;
    margin: 0px;
}
.img-icon{
    margin-top: -5px;
    width: 70%;
    margin-bottom: 5px;
}
.location{
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}
#weatherInformation{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}
#weather{
    width: 50%;
    float: left;
    margin-left: 20px;
}
.today {
    font-size: 45px;
    text-align: center;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}
.iconWeather{
    float: right;
    margin-top:-5px;
    width: 42% !important;
}

.iconWeather img{
    height: 100% !important;
    margin-left: 25px;
}
.weather-desc{
    width: 35%;
    height: 100%;
    float: right;
    margin-right: 10px;
}
.weather-desc > span {
    display: -webkit-box;
    padding: 10px 5px;
    background: rgba(0, 0, 0, 0.54);
    -webkit-border-radius: 5px;
    font-size: 13px;
    margin-top: 10px;
    text-align: -webkit-center;
}
.inner > span{
    font-size: 11px;
    color: black;
    text-align: center;
}
.content-info{
    width:100%;
    display:flex;
    margin-bottom: 50px;
    flex-flow: row wrap;
}
#dataProvided{
    float:right;
    font-size: 11px;
    color: white;
}


/**otros destinos*/
.otros_destinos {
    text-align: center;
}
.otros_destinos h2{
    background:#009bf8;
    color:#fff;
    text-shadow: none;
}
.enlace-destinos{
    width: 214px;
    position: relative;
    float: left;
    margin: 5px;
    overflow: hidden;
    cursor: pointer;
}
.ofer__destinos {
    max-width: 212px;
    width: 100%;
    margin: 10px 10px;
    display: inline-block;
}
.ofer__destinos a {
    position: relative;
    margin: 5px;
    overflow: hidden;
    width: 214px;
    position: relative;
    float: left;
    margin: 5px;
    overflow: hidden;
}
.contenido-destinos:hover {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -o-transform: scale(1.07);
    transform: scale(1.07);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.contenido-destinos {
    position: relative;
    box-sizing: border-box;
    min-height: 120px;
    background-size: cover;
    background-position: 50% 50%;
    padding: 5px;
    box-shadow: 0px 6px 15px #ccc;
    transition: all .5s ease;
}
.ofer__destinos h3 {
    left: 0;
    top: 65%;
    width: 100%;
    font-size: 22px;
    line-height: 20px;
    color: #fff;
    position: absolute;
    text-shadow: 2px 2px 2px #000;}
/*slide*/
.wrap {
    min-height: 290px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #120103;
    color: #fff;
    text-align: center;
    box-shadow: 0px 5px 13px #000;
}
.botones {
    background: rgba(255, 255, 255, 0.41);
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 14px #000;
    margin-left: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 900;
}
.botones label {
    border-radius: 100%;
    width: 17px;
    background: #ccc;
    float: left;
    z-index: 100;
    border: 4px solid #fff;
    box-shadow: 0 0 3px #888;
    margin: 5px 8px;
    font-size: 6px;
    bottom: 0;
}
.botones label:hover {
    background: #009bf8;
    border: 4px solid #fff;
    opacity: 0.7;
}
.slide {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 10;
    padding:  8em 1em 0;
    background-color: #120103;
    background-position: 50% 50%;
    background-size: cover;
    transition: left 0s .75s;
}
.slide h2{
    color: #fff;
    text-shadow: 1px 1px 3px #000;
    position: absolute;
    bottom: -14px;
    right: 7px;
    font-size: 16px;
    max-width: 70%;
}

[id^="slide"]:checked + .slide {
    left: 0;
    z-index: 100;
    transition: left .65s ease-out;
}
/*/fin slide/*/
@media screen and (min-width: 50em) {
    .destinos-promo {
        float: left;
        padding: 0 24px;
        flex: 0 0 50%;
    }
    .sitios-tur-izq {
        float: left;
        width: 45%;
        padding-left: 9px;
        padding-right: 9px;
    }
    .sitios-tur-der {
        float: left;
        width: 55%;
        padding-left: 9px;
        padding-right: 9px;
    }

    .img-area {
        position: relative;
        width: auto !important;
        height: 170px;
        color: white;
    }
    .week-forecast {
        list-style: none;
        margin: 0px;
        background-image: url('/assets/aviatur_assets/img/weather/fondo.jpg');
        padding: 0;
        overflow: hidden;
        width: auto !important;
        height: 130px !important;
    }
    .week-forecast > li {
        float: left;
        height: 130px !important;
    }
}
/* Fin CSS Slide Document */
@media screen and (min-width: 967px) and (max-width: 1051px){
    .today {
        font-size: 40px !important;
    }
    .iconWeather img {
        margin-left: 20px;
    }
}

@media screen and (min-width: 882px) and (max-width: 966px){
    .today {
        font-size: 35px !important;
        margin-top:15px;
    }
    .iconWeather img {
        margin-left: 12px;
        margin-top: 0px !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 881px){
    .today {
        font-size: 30px !important;
        margin-top: 10px;
    }
    .iconWeather img {
        margin-left: 10px;
        margin-top: 0px !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 450px) and (max-width: 516px){
    .today{
        font-size: 35px !important;
    }
    .iconWeather img {
        margin-left: 15px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
}

@media screen and (min-width: 407px) and (max-width: 449px){
    .today{
        font-size: 30px !important;
    }
    .iconWeather img {
        margin-left: 15px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 406px){
    .today{
        font-size: 29px !important;
        margin-left: 10px !important;
    }
    .iconWeather img {
        margin-left: 10px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 350px){
    .today{
        font-size: 27px !important;
        margin-left: 0px !important;
    }
    .iconWeather img {
        margin-left: 5px;
        margin-top: 10px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:33.3% !important;
    }
}
/*Fin Css Destinos*/

/***Vuelos**/

.promo-box-vuelos {
    width: 100%;
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    margin-bottom: 18px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
}
.promo-box-vuelos p{
    text-shadow: 1px 1px 2px #000;
}
.promo-box__grid-destinos {
    padding: 0px 10px;
}
.cortinilla-vuelos {
    background: linear-gradient(rgba(0, 0, 0, 0.86) 46%, transparent 100%);
    width: 40%;
    height: 100%;
    float:left;
}
.cortinilla-vuelos-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.86) 46%, transparent 100%);
    width: 40%;
    height: 100%;
    float: right;
}
.promo-box__conditions-vuelos {
    color: #fff;
    font-weight: 100;
    text-align: justify;
    padding: 18px;
    height:100%;}

.promo-box__conditions-vuelos span {
    width: 100%;
    float: left;
    border-bottom: solid 1px;
    font-size: 30px;
    margin-bottom: 18px;
    padding-bottom: 5px;
    text-shadow: 1px 2px 3px #000;
}
.ver-mas-vuelos {
    float: left;
    width: 100%;
    background: #009bf8;
    border: solid 1px #0087dd;
    color: #fff;
    padding: 10px;
}
.ver-mas-vuelos p {
    margin: 0;
    float: right;
}
.ver-mas-vuelos-content a{
    color:#fff;
    font-weight:100;
}

@media screen and (max-width: 44.9375em){
    .cortinilla-vuelos, .cortinilla-vuelos-2{
        width: 100%;
        background: linear-gradient(transparent , rgba(0, 0, 0, 0.86) 60%);
    }
}
/**Fin Vuelos**/

/**Ferias y fiestas**/
.month {
  max-width: 365px;
  width: 100%;
  margin: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  vertical-align: top;
  border-radius: 5px;
  box-shadow: 0px 0px 9px #ccc;
}

#feria {
  width: 100%;
  height: 180px;
  &.islink {
    cursor: pointer;
    -webkit-transition: all 0.7s ease;
    transition: all 0.5s ease;
    &:hover {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0, 0, 0, 0.42), 0 2px 4px -1px rgba(0, 0, 0, 0.35);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0, 0, 0, 0.42), 0 2px 4px -1px rgba(0, 0, 0, 0.35);
    }
  }
}

.content-feria {
  border-bottom: 1px solid #a8a7a7;
  color: #009bf8;
  padding: 10px 0;
  min-height: 180px;
  max-height: 200px;
}

.image-feria {
  float: left;
  width: 48%;
  background-size: cover;
  height: 100%;
}

.info-feria {
  width: 52%;
  float: right;
  text-align: center;
  height: 100%;
  background-color: #f3f3f3;
}

.otras-ferias {
  color: #009bf8;
  padding: 10px 0;
  min-height: 120px;
  max-height: 200px;
}

@media screen and (min-width: 300px) and (max-width: 370px) {
  #feria {
    width: 100%;
    height: 150px !important;
  }
  .content-feria {
    min-height: 290px !important;
    max-height: 300px !important;
  }
  .image-feria {
    float: none !important;
    width: 100% !important;
    height: 120px !important;
  }
  .info-feria {
    width: 100% !important;
    float: none !important;
  }
  .otras-ferias {
    min-height: 130px;
  }
  .month {
    width: 100%;
    margin: 10px 0px 8px 0px;
  }
}
/**Fin ferias y fiestas**/
.embed-responsive-4by3{
    @media screen and (max-width:1023px){
        margin-top: 57px;
    }       
}

.inline-full{
    display: inline-block;
    width: 100%;
}

.card {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.card.contenido h2{
    font-size: 1.3rem;
    font-weight: 600;
}

.card.contenido p{
    font-weight: 300;
    color: #000000;
    text-align: justify;
    margin-right: 20px;
}

.card.contenido .btn-check-in{
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
}

.card.card-aerolineas{
    cursor: pointer;
    h3{
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-top: 10px;
    }
    @include media-query(palm) {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

}

.card a, .card a:hover{
    text-decoration: none
}
.row-contenido{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;    
}
.collapse{
    display: none;
}