///*------------------------------------*\
//    #RESUME-CAR
//\*------------------------------------*/

.resume-car__description {
    padding: 0 $inuit-base-spacing-unit--small;
}

.resume-car .detail-car__title-info {
    // reproduce frame-hero styling
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    margin-top: -1px;
    margin-left: -1px;
    padding: $inuit-base-spacing-unit--small 0;
    border-radius: $base-round $base-round 0 0;
    border: 1px solid lighten($color-frame-hero, 15%);
    line-height: $inuit-base-line-height*2;
    font-size: $inuit-base-font-size;
    @include font-weight(bold);
    color: $color-frame-hero-type;
    background-color: $color-frame-hero;
}
.resume-car .detail-car__image-wrapper {
    margin-top: $inuit-base-line-height*2+10px;
}
.resume-car .detail-car__image {
    width: initial;
}
.resume-car .detail-car__destination-first {
    margin-top: $inuit-base-line-height*2+10px;
}