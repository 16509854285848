.search-multi {
    .search__col-flight-passengers {
        display: none;
    }
    @include media-query(lap-and-up) {
        .search__light--box & .search__row-main {
            margin-bottom: 0;
        }
    }
    @include media-query (desk) {      
        .search__hotel-passengers-block {
            margin-top: -2.8em;
            right: 0%;   
        }
        .search__hotel-passengers-block::before {
            left: 220px;
        }
        .search__link-options {
            margin-right: 50em;
        }
        .hotelSearc-btn {
            margin-bottom: 10px;
            input {
                font-size: 18px !important;
            }
        }
    }
}
.multiSearch {
    @include media-query (desk) {
        .search__col-flight-city {
            width: 30%;
        }
        .search__col-flight-date {
            width: 19.66667%;
        }
        .search__hotel-passengers-block {
            margin-top: -2.8em;
            right: 0%;   
        }
    }
}