/*------------------------------------*\
    #The alert message box
\*------------------------------------*/
.alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 1rem 2rem;
    margin: 0 0 20px 0;
    border-radius: 4px;
}
 .alert .alert-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1.25rem 0 0;
}

.alert.alert-warning .alert-icon i {
    //color: #111;
}

.alert .alert-icon i {
    font-size: 2.4rem;
}
.alert .alert-text {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 13px;
    font-weight: 300;
}

.alert.warning {
    background-color: #ffb822;
    color: #111;
}
.alert.success {
    background-color: #4CAF50;
}
.alert.danger {
    background-color: #f44336;
}
.alert.info {
    background-color: #2196F3;
}
.alert.primary {
    background-color: #007bc5;
}
.alert.default {
    background-color: #d0d2d4;
}

