///*------------------------------------*\
//    #DETAIL-PACKAGE
//\*------------------------------------*/

.detail-package__carousel {
    float: left;
    max-width: 745px;
}
    .detail-package__carousel__image {
        height: 500px;
        background: no-repeat center center;
        background-size: cover;
        @include media-query(palm){
            height: 310px;
        }
    }


.detail-package__corpbanca{
    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.detail-package__codensa{
    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.detail-package__corpbanca-background{
    background: url(/assets/common_assets/img/package/banner_corpbanca_detalle.png) no-repeat center right; 
    min-height: 145px;
}

.detail-package__codensa-background{
    background: url(/assets/common_assets/img/package/banner_codensa.jpg) no-repeat center right; 
    min-height: 145px;
}

// .detail-package__bbva-background{
//     background: url(/assets/common_assets/img/package/bbva_banner.jpg) no-repeat center right;    
//     min-height: 145px;
// }


.detail-package__corpbanca-table{
    display: table; 
    min-height: 50px;
    margin-left: 8%;
    font-size: 19px;
}

.detail-package__corpbanca-table div{
    display: table-cell; 
    vertical-align: middle;
}


/* NEW STUFF BELOW */

.detail-package__info {
    padding: $inuit-base-spacing-unit 0 !important;
    background-color: #fff;
}

.package-detail_frame-group {
    // margin-left: 20px;
    @include media-query(palm) {
        margin-left: 0px;
    }
}

.wrapper-susy div.infoHotel-pkgSubmit_column {
    position: relative;
    @include media-query(palm) {
        position: fixed;
        width: 100%;
        top: 10px;
        left: 0px;
        z-index: 99;
    }
    .frame-group{
        @include media-query(palm) {
            background: #fff;
        }
    }
}

.wrapper-susy div.infoHotel_roomTypes-column {
    position: relative;
    @include media-query(palm) {
        width: 100%;
    }
}
.title-detail-pack{
    @include media-query(palm){        
        line-height: 1.3;
    }
}
p.dispo-package__detail{
    margin: 12px 0;
}
.tel-package{
    margin: 20px 0px;
    @include media-query(palm){
        margin: 8px 0px;
    }
}
.all-detail-package{
    margin-top: 70px;
    @include media-query(palm){
        margin-top: 35px;
    }
}
.wpp-package{
    margin-bottom:10px
}
.detail-content-price{
    margin-top: 0 !important;
}