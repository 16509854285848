///*------------------------------------*\
//    #DEFAULT
//\*------------------------------------*/


$inuit-base-font-size:        16px;
$inuit-base-line-height:      18px;
.list-style-n li{
    list-style: none;
}
.list-style-n-img li{
    list-style-image: none !important;
}