///*------------------------------------*\
//    #DISPO-CAR
//\*------------------------------------*/


// Search Resume //

.dispo-car__resume-search {
    float: left;
    width: 100%;
    margin-bottom: 1.2rem;
    border: 0.5px solid $color-dispo;
    border-radius: 5px;
    text-align: center;
    color: #000;
    background-color: rgba(0, 0, 0, 0.017);
    @include media-query(palm) {
        & span{
            font-size: 13px;
        }
        & p{
            font-size: 8px;
        }
    }
}
.dispo-car__cities {
    float: left;
    width: 40%;
    padding: 0 2%;
    margin: 2%;
}
.dispo-car__city {
    width: 45%;
}
.dispo-car__city-pickup {
    float: left;
}
.dispo-car__city-dropoff {
    float: right;
}
.dispo-car__city-code {
    font-size: 20px;
    width: 100%;
}
.dispo-car__city-name {
    margin: 0;
    font-size: 13px;
}
.dispo-car__search-arrow-wrapper {
    width: 10%;
}
.dispo-car__resume-search .dispo-car__search-arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    background: url(/assets/common_assets/img/icon/flecha-doble.svg) no-repeat 0 12px;
    background-size: contain;
    @include media-query(palm) {
        width: 12px;
    }
}
.dispo-car__dates {
    float: left;
    width: 50%;
    padding: 0 2%;
    margin: 2%;
    border-left: 1px #ccc solid;
}
.dispo-car__date {
    float: left;
    width: 50%;
}
.dispo-car__trip-date {
    width: 100%;
    font-size: 20px;
}
.dispo-car__trip-day {
    margin: 0;
    font-size: 13px;
}

// Classification filter //

.dispo-car__classification {
    width: 100%;
    margin-bottom: $inuit-base-spacing-unit;
}
.dispo-car__class-text {
    display: inline-block;
    margin-right: 5%;
    @include media-query(portable) {
        width: 100%;
        margin: 14px 0;
    }
    @include font-weight(bold);
}
.dispo-car__class-button {
    position: relative;
    display: inline-block;
    width: 20%;
    color: $color-gree-text;
    @include media-query(portable) {
        width: 49%;
    }
    height: 46px;
    vertical-align: middle;
    @include font-weight(bold);
    &.is-selected {
        cursor: default;
    }
    & span {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }
}

// Sidebar filter //

.dispo-car__filter h2, 
.dispo-car__filter h3 {
    color: $color-dispo;
}
.filter__checkbox-label {
    padding-left: 3px;
}

// Results //

.dispo-car__frame-group,
.dispo-car__frame-head,
.dispo-car__frame-border,
.dispo-car__frame-content {
    float: left;
    width: 100%;
}

// frame-head

.dispo-car__frame-group .dispo-car__frame-head.frame:first-of-type {
    margin-bottom: -1px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: 5px 5px 0 0;
    box-shadow: none;
    padding-left: 0;
    & section {
        @include media-query(palm){
            margin-bottom: $inuit-base-spacing-unit--small;
        }
    }
}
.dispo-car__title {
    text-align: initial;
    color: $color-gree-text;
    @include font-size(23px);
    @include font-weight(bold);
}

// frame-content

.dispo-car__frame-border {
    border-left: none;
    border-right: none;
}
.dispo-car__frame-group div.dispo-car__frame-border:last-of-type:not(:first-of-type) {
    border-bottom: none;
}
.dispo-car__frame-content {
    padding-top: $inuit-base-spacing-unit--small;
}
.dispo-car__frame-content.frame {
    border-radius: 0;
    border-top: 1px solid transparent;
    border-right: 0;
    border-bottom: 1px solid $color-frame-border;
    border-left: 0;
    .dispo-car__frame-group div.dispo-car__frame-border:last-of-type:not(:first-of-type) & {
        border-bottom: 1px solid transparent;
    }
}
.dispo-car__frame-group div.dispo-car__frame-border.dispo-car__frame-last {
    border-bottom: none;
    border-radius: 0 0 $base-round $base-round;
    & .dispo-car__frame-content.frame {
        border-bottom: 1px solid transparent;
    }
}

.dispo-car__col-description {
    float: left;
    width: 60%;
    @include media-query(palm) {
        width: 100%;
        text-align:center;
    }
}

.dispo-car__type-image {
    width: 33.333%;
    padding-bottom: 5px;
    margin: 0 auto;
}
.dispo-car__type-image p {
    margin: 0;
    font-size: 10px;
    text-align: center;
}
.dispo-car__type-image img {
    width: 100%;
}

.dispo-car__features-wrapper {
    width: 33.333%;
    padding-top: $inuit-base-spacing-unit--small;
    margin-left: $inuit-base-spacing-unit;
    @include media-query(palm) {
        width: 60%;
        margin-left: $inuit-base-spacing-unit--small;
    }
}
.dispo-car__features-block {
    margin: $inuit-base-spacing-unit--small 0;
}
.dispo-car__features {
    display: inline;
    margin-left: $inuit-base-spacing-unit--small;
}
.dispo-car__features-count {
    vertical-align: middle;
    color: #333;
}
.dispo-car__features-margin {
    margin-left: 4px;
}
.dispo-car__features-tooltip {
    display: inline-block;
}
.dispo-car__icon {
    width: 20px;
}

.dispo-car__logo {
    width: 20%;    
    padding-top: $inuit-base-spacing-unit--small;
    margin: $inuit-base-spacing-unit--small 0 $inuit-base-spacing-unit--small $inuit-base-spacing-unit;
    @include media-query(palm) {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
    }
}
.dispo-car__logo img {
    width: 100%;
    @include media-query(palm) {
        width: 25%;
    }
}

.dispo-car__price-wrapper {
    float: right;
    width: 100%;    
    padding: $inuit-base-spacing-unit--small;
    border-radius: $base-round;
    @include media-query(lap-and-up){
        width: 28%;
        margin: 0 $inuit-base-spacing-unit $inuit-base-spacing-unit--small;
    }
    @include media-query(palm) {
        text-align: center;
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.dispo-car__first-price {
    border: 1px solid #ccc;
    @include linear-gradient(top,  #fff 0%, #ccc 50%);
}
.dispo-car__price-wrapper .dispo-car__logo-small {
    width: 75px;
}
.dispo-car__price-label {
    @include font-size(12px);
}
.dispo-car__price {
    font-size: 20px;
    @include font-weight(bold);
}
.dispo-car__submit {
    @include media-query(lap-and-up){
        margin-top: $inuit-base-spacing-unit--small;
    }
}

// frame-foot

.frame-group div.dispo-car__frame-foot.frame {
    float: left;
    width: 100%;
    padding: 5px;
    border-right: .5px solid $color-dispo;
    border-bottom: .5px solid $color-dispo;   
    border-left: .5px solid $color-dispo;
    text-align: center;
}
.icon-car-info{
    font-size: 25px;
    color: #005eb8;
    margin-right: 5px;
}