/*------------------------------------*\
    #FORMS
\*------------------------------------*/

input[type='checkbox'] {
    height: 12px;
    margin: 0 0 1px 0;
    vertical-align: middle;
}

.list-radio {
    display: inline-block;
    position: relative;
    height: 10px;
    width: 10px;
    border: 1px solid $color-main;
    border-radius: 50%;
    & > .list-checked {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        background-color: $color-main;
        border-radius: 50%;
    }
}
.list-radio--big {
    @extend .list-radio;
    padding: $inuit-base-spacing-unit--small;
}

.list-checkbox {
    @extend .list-radio;
    border-radius: 20%;
    & > .list-checked {
        border-radius: 20%;
    }
}

.checkbox-label {
    display: inline;
}
.tittle-promo{
    position: absolute;
    bottom: 1px;
    left: 15px;
    color: gainsboro;
}
@include media-query (palm){
    #newsletter_form_email{
        margin-bottom: 12px;
    }
    .tittle-promo{
        bottom: 6px;
    }
}