/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 14/02/2018, 03:41:27 PM
    Author     : lina.cortes
*/
.toggle {
    position: relative;
    width: 290px;
    margin-right: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toggle__checkbox {
    display: none;
    &:checked + .toggle__label {
        background-color: #009bf8;
        .toggle__off {
            opacity: 0;
        }
        .toggle__on {
            opacity: 1;
        }
        .toggle__switch {
            margin-left: 44px;
        }
    }
}

.toggle__label {
    display: block;
    overflow: hidden;
    background-color: #ddd;
    padding: 2px;
    cursor: pointer;
    border-radius: 30px;
    text-transform: uppercase;
    .toggle__on {
        color: #fff;
        opacity: 0;
        float: left;
        margin: 8px 0 0 10px;
    }
    .toggle__off {
        color: #333;
        opacity: 1;
        float: right;
        margin: 8px 10px 0 0;
    }
    .toggle__switch {
        display: block;
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 50%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
}
