///*------------------------------------*\
//    #DETAIL-CRUISE-CABINS
//\*------------------------------------*/
 
.detail-cruise__content {
    @include span(4 of 6);
    @include media-query(portable) {
        @include span(full);
        padding-right: 0;
        padding-left: 0;
    }
}

.detail-cruise__sidebar {
    @include media-query(desk) {
        @include span(2 of 6);
    }
}

.detail-cruise__resume {
    padding-bottom: $inuit-base-spacing-unit;
}

.detail-cruise__deck-switch.is-active {
    @include font-weight(bold);
}

.detail-cruise__show-ship-map {
    padding: $inuit-base-spacing-unit--small 0;    
}
.ship-map-modal__wrapper {
    padding: 0 $inuit-base-spacing-unit;
}

.ship-map__images {
    margin-bottom: $inuit-base-spacing-unit;
}
.ship-map__deck-image {    
    opacity: .9;
    z-index: -1;
}
.ship-map__deck-position {
    position: absolute;    
    right: 0;
    padding: $inuit-base-spacing-unit--small;    
    background-color: #fff;
    border: 1px solid $color-frame-border;
    z-index: 1;
    @include font-weight(bold);
    &.deck--forward {
        top: 0;
    }
    &.deck--middle {
        bottom: 0;
    }
}
.ship-map__cabin-highlight {
    position: absolute;    
    cursor: pointer;    
}
.cabin-highlight__box {
    height: 100%;
    border: 3px solid rgba($color-main, .4);
    background-color: rgba($color-main, .2);
    &.pulse-highlight {
        border-color: rgba($color-main, 1);
        animation: pulse 1.6s ease-in-out infinite alternate;
    }
}
@include keyframes(pulse) {
    0%, 50%, 100% {
        opacity: 1;
        transform: scale(1);
    }
    25%, 75% {
        opacity: .8;
        transform: scale(1.3);
    }
}
.ship-map__loader {
    text-align: center;
    margin: $inuit-base-spacing-unit 0;
}

.detail-cruise__cabins-table {
    margin: $inuit-base-spacing-unit--small 0 $inuit-base-spacing-unit;
}