/*------------------------------------*\
    #DETAIL-BUS
\*------------------------------------*/

/*.detail-bus__frame {
    display: inline-block;
    width: 39%;
    vertical-align: top;
    border-radius: 5px;
    padding: 20px 6px;
}

.detail-bus__frame .detail-bus__image {
    background-size: contain;
    width: 100%;
    display: block;
    text-align: right;
}*/

/*.detail-bus__frame .detail-bus-details {
    display: block;
    width: 100%;
}*/

/*.detail-bus__frame .detail-bus-details .detail-bus-details__schedule {
    display: block;
    text-align: center;
}*/

/*.detail-bus__frame .detail-bus-details .detail-bus-details__schedule .detail-bus-details__container {
    display: inline-block;
    text-align: left;
}*/

/*.detail-bus__frame .detail-bus-details .detail-bus-details__schedule .detail-bus-details__container .detail-bus-details__info {
    font-size: 26px;
}*/

/*.detail-bus__frame .detail-bus-details .detail-bus-details__services {
    display: block;
    text-align: left;
}*/

/*.detail-bus__frame .detail-bus-price {
    text-align: right;
    font-size: 26px;
}*/

/*.detail-bus__map {
    display: inline-block;
    width: 59%;
    text-align: center;
    vertical-align: top;
}*/

.detail-bus__map__floor-container {
    text-align: center;
}

.detail-bus__map__floor {
    padding: 40px 5px 5px;
    border: 2px solid #666;
    border-radius: 75px 75px 20px 20px;
    width: 100%;
    max-width: 250px;
    text-align: center;
    position: relative;
    display: inline-block;
    border-spacing: 2px;
    text-align: center;
}

.detail-bus__map__floor-body {
    display: table;
    width: 100%;
}

.detail-bus__map__row {
    display: table-row;
    text-align: left;
}

/*.detail-bus_frame_module {
    margin-top: 5px;
    display: block;
    width: 100%;
    border: 1px solid #eee;
    padding: 10px;
}*/

.detail-bus__seat-selection {
    padding: $inuit-base-spacing-unit--small $inuit-base-spacing-unit $inuit-base-spacing-unit;
    border: 1px solid #999;
}

/*.detail-bus__seatselection_actions {
    text-align: center;
}*/

/*.bus-selection_trip-container {
    width: 100%;
}*/

/*.bus-selection_trip-container h4{
    color: #4AB7F9;
    margin-bottom: 2px;
}*/

/*.bus-selection_trip-container .bus-selection_bus-table{
    width: 100%;
    display: table;
}*/

/*.bus-selection_trip-container .bus-selection_bus-table .bus-selection_bus-row {
    display: table-row;
}*/

/*.bus-selection_trip-container .bus-selection_bus-table .bus-selection_bus-row .bus-selection_bus-row-cell {
    display: table-cell;
    width: 33%;
    padding: 2px;
}*/


/*@media (max-width: 650px) {
    .detail-bus__frame, .detail-bus__map {
        width: 100%;
        display: block;
    }
}*/

.bus-selection__trips > .bus-selection__trip-container:not(:last-child) {
    border-bottom: 1px dashed $color-frame-border;
}
.bus-selection__trips > .bus-selection__trip-container:last-child {
    border-bottom: 1px solid $color-frame-border;
}

.detail-bus__map__cell {
    display: table-cell;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    width: 45px;
    height: 40px;
    vertical-align: middle;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.bus-seat {
        background-image: url(/assets/common_assets/img/bus/icon/map/seat.svg);
    }
    &.bus-seat--occupied {
        background-image: url(/assets/common_assets/img/bus/icon/map/seat--occupied.svg);
    }
    &.bus-seat.active {
        background-image: url(/assets/common_assets/img/bus/icon/map/seat--active.svg);
    }
    &.bus-hall {
        width: $inuit-base-spacing-unit * 3;
    }
    &.bus-door {
        background-image: url(/assets/common_assets/img/bus/icon/map/door.svg);
    }
    &.bus-tv {
        background-image: url(/assets/common_assets/img/bus/icon/map/tv.svg);
    }
    &.bus-stairs {
        background-image: url(/assets/common_assets/img/bus/icon/map/stairs.svg);
    }
    &.bus-wc {
        background-image: url(/assets/common_assets/img/bus/icon/map/wc.svg);
    }
    &.bus-table {
        background-image: url(/assets/common_assets/img/bus/icon/map/table.svg);
    }
}

.bus-seat__num {
    background-color: rgba(0,0,0,0.1);
    padding: 2px 0;
    width: 24px;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 16px;
}

.detail-bus__map__caption .detail-bus__map__cell {
    width: 1.8em;
    height: 1.8em;
}

.detail-bus__map__cell-caption {
    display: table-cell;
    vertical-align: middle;
    padding: 0 $inuit-base-spacing-unit--small;
}

/* Detail */

/*.detail-bus__col-image__img {
    max-height: 80px;
}

.detail-bus__col-image__no-img {
    display: block;
    background-color: $gray-plain-light;
}*/

/*.detail-bus__seatselection_actions{
    display: none;
    padding: $inuit-base-spacing-unit;
}*/