///*------------------------------------*\
//    #PAYOUT-EXTRAS/ADDITIONALS
//\*------------------------------------*/

.payoutExtras__container {
    display: table;
    position: relative;
    width: 100%;
    min-height: 80px;
    padding: 5px;
    border: 1px solid #eee;
    /*border-bottom-color: transparent;*/
    table-layout: fixed;
    @include media-query(palm) {
        width: calc(100% + #{$inuit-base-spacing-unit*2});
        margin-left: -$inuit-base-spacing-unit;
        margin-right: -$inuit-base-spacing-unit;
    }

    & > div {
        display: table-cell;
        vertical-align: middle;
    } 
}
    .payoutExtras__container-selector {
        width: 5%;
        input {
            top: 50%;
            left: 50%;
            display: block;
            margin: 0 auto;
        }
    }
    .payoutExtras__container-label {
        width: 60%;
    }
        .payoutExtras__container-checkboxLabel {
            min-height: unset;
            font-size: unset;
            line-height: unset;
            margin-bottom: unset;
        }
        .payoutExtras__container-description {
            font-size: 12px;
            color: #585858;
        }
            .payoutExtras__container-infolink {
                margin-top: 5px;
            }

    .payoutExtras__container-price {
        padding-right: $inuit-base-spacing-unit--small;
        &.payoutExtras__container-price--palm {
            width: 100%;
            text-align: center;
        }
        &.payoutExtras__container-price--lap-and-up {
            width: 17.5%;
            text-align: right;
            @include media-query(palm) {
                // tuly hide element to wipe remnant width in table
                display: none;
            }
        }
    }
        .payoutExtras__container-value {
            font-size: 20px;
            color: $color-main;
            display: block;
        }
        .payoutExtras__container-legend {
            font-size: 12px;
            color: #444;
            display: block;
            @include media-query(lap-and-up) {
                text-align: right;
            }
        }

    .payoutExtras__container-img-wrapper {
        @include media-query(palm) {
            padding-left: $inuit-base-spacing-unit--small;
        }
        @include media-query(lap-and-up) {
            width: 60px;
        }
    }
        .payoutExtras__container-img {
            max-width: 60px;
            border-radius: $base-round*2;
            border: 1px #fbfbfb solid;
            box-shadow: 1px 1px 1px 1px #eee;
            display: block;
            margin: 0 auto;
        }

    .payoutExtras__container-help {
        width: 25px;
    }
        .payoutExtras__container-helpSymbol {
            position: absolute;
            top: 2px;
            right: 2px;
            font-size: 25px;
            line-height: 0;
        }

.payoutExtras__helptext {
    padding: $inuit-base-spacing-unit;
    font-size: .75em;
    border-top: 1px dashed #eee;
    border-right: 1px solid #eee;
    margin-top: -1px;
    @include media-query(palm) {
        width: calc(100% + #{$inuit-base-spacing-unit*2});
        margin-left: -$inuit-base-spacing-unit;
        margin-right: -$inuit-base-spacing-unit;
    }
    h4, ul {
        margin-bottom: 0;
    }
}


.experience_container {
    min-height: 64px;
}

.experience_container .experience-status {
    font-size: .6em;
}

.experience_container .experience-item-cell{
    display: table-cell;
    vertical-align: middle;
}

.experience_container .experience-item-cell.experience-column-1{
    width: 5%;
    top: 50%;
    text-align: center;
}

.experience_container .experience-item-cell.experience-column-2{
    width: 65%;
}

.experience_container .experience-item-cell.experience-column-3{
    width: 30%;
    text-align: right;
}

.experience_container .experience-name {
    font-weight: 500;
}

.experience_container .experience-price {
    display: block;
    padding-right: $inuit-base-spacing-unit--tiny;
    font-size: 1.2em;
    color: $color-main;
}
