/*------------------------------------*\
    #LABEL
\*------------------------------------*/

label {
    display: block;
    margin-bottom: $inuit-base-spacing-unit--tiny;
}

.label-inuit-inline {
    display: inline-block;
    margin-bottom: 0;
}
.label-inuit-inline.black_friday {
    color: white;
}

.label--error {
    color: $color-label-error !important;
    cursor: default !important;
    float: left;
}

.label-top {
    margin-bottom: 2px;
    font-weight: 100;
    font-size: 13px;
    @include attention() {
        cursor: default;
    }
}