                    ///*------------------------------------*\
//    #NAV-MAIN
//\*------------------------------------*/
.nav-main {
    float: left;
    margin: 25px 0 0px;
    @include media-query(palm) {
        transition: opacity .2s;
        overflow-y: hidden;
        overflow-x: scroll;
        position: relative;
        width: 100%; overflow: auto;
        .list-inline {
            transition: all .3s ease-out;
            display: flex;
        }
    }
    .list-inline {     
        @media screen and (min-width: 722px) and (max-width: 1123px){
            display: flex; 
            flex-wrap: wrap;     
        
        }
    }
}
.nav-main__grid {
    float: left;
    @include media-query(palm) {
        margin: 0;
        padding: 0;
        border: 0;
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        font-style: inherit;
        line-height: inherit;
        vertical-align: baseline;
        font-variant: inherit;
        a {
            min-width: 80px;
            display: inline-flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            text-decoration: none;
            cursor: pointer;
            overflow: hidden;
        }
    }
    @media screen and (min-width: 722px) and (max-width: 1123px){
        margin-bottom: 16px;
    }
    @include media-query(lap) {
        @include span(1 of 10);
    }

}
.nav-main__grid.is-corporate {
    @include media-query(palm) {
        display: none;
    }
}
.nav-main__item {
    position: relative;
    width: 80px;
    text-align: center;
    @include media-query(palm) {
        width: 100%;
        padding: $inuit-base-spacing-unit--small 0 0;
        color: $color-main;
    }
    @include media-query(lap-and-up) {
        color: #fff;
        @include attention() {
            &:not(.is-active) .nav-main__icon {
                //animation: bg-expand 300ms ease 1 forwards;
            }
            &:not(.is-active) .nav-main__title {
                //animation: slide-up 300ms ease 1 forwards;
            }
        }
        &.is-active .nav-main__icon {
          background-size: 80%;
          filter: grayscale(1) brightness(4.5);
          background-position-y: -1px;
          background-position-x: 10px;
        }
        &.is-active .nav-main__title {
            opacity: 1;
            font-weight: bold;
            border-bottom: 2px inset $color-main;
        }
    }
    @include media-query (palm) {
        &.is-active .nav-main__title {
            font-weight: bold;
            margin-top: 12px;
        }
        &.is-active .nav-main__icon {
            border: 0;
            filter: grayscale(1) brightness(4.5);
            background-position-x: 2px;
        }
    }
}
.nav-main__icon {
    width: 100%;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: -1px;
    @include media-query(palm) {
        background-size: 60px;
        background-position-y: 11px;
        width: 56%;
        border: 2px solid $color-main;
        border-radius: 50%;
        padding: 0px 28px;
        & img {
            height: 52px;
        }
        @include attention() {
            background-color:rgba(0, 94, 184, 0.3);
        }
    }
    @include media-query(lap-and-up) {
        width: 60px;
        background-size: 100%;
    }
}
@media screen and (min-width: 570px) and (max-width: 722px){
    .nav-main__icon {
        width: 38%;
    }
}
@media screen and (min-width: 470px) and (max-width: 530px){
    .nav-main__icon {
        width: 48%;
    }
}
@media screen and (min-width: 530px) and (max-width: 570px){
    .nav-main__icon {
        width: 42%;
    }
}
@media only screen and (max-width: 370px) {
    .nav-main__icon {
        width: 65%;
    }
}
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .nav-main__icon {
        width: 60%;
    }

}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
{
    .nav-main__icon {
        width: 70%;
    }
}
.is-active .nav-main-circle{
  top: -10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #005eb8;
  border-radius: 50%;
  position: absolute;
  margin: 0px 21px;
  @include media-query(palm) {
    top: 5px;
    right: -14px;
    width: 58px;
    height: 58px;
  }
}
.nav-main__icon--disney {
    background-image: url(/assets/common_assets/img/navbar/disney.svg);
}

.nav-main__icon--flight {
    background-image: url(/assets/common_assets/img/navbar/flight.svg);
}
.nav-main__icon--flighthotel {
    background-image: url(/assets/common_assets/img/navbar/flight-hotel.svg);
        @include media-query (palm) {
            background-size: 72px;
            background-position-y: 8px;
        }
}
.nav-main__icon--hotel {
    background-image: url(/assets/common_assets/img/navbar/hotel.svg);
}
.nav-main__icon--car {
    background-image: url(/assets/common_assets/img/navbar/cars.svg);
}
.nav-main__icon--train {
    background-image: url(/assets/common_assets/img/navbar/trains.svg);
}
.nav-main__icon--package {
    background-image: url(/assets/common_assets/img/navbar/vacations.svg);
}
.nav-main__icon--insurance {
    background-image: url(/assets/common_assets/img/navbar/insurance.svg);
}
.nav-main__icon--cruise {
    background-image: url(/assets/common_assets/img/navbar/cruise.svg);
}
.nav-main__icon--corporate {
    background-image: url(/assets/common_assets/img/navbar/icon-corporate.svg);
}
.nav-main__icon--bus {
    background-image: url(/assets/common_assets/img/navbar/bus.svg);
}
.nav-main__icon--experience {
    background-image: url(/assets/common_assets/img/navbar/expirience.svg);
}
.nav-main__icon--contact {
    background-image: url(/assets/common_assets/img/navbar/icon-contact-blue.svg);
}
.nav-main__title {
    width: 100%;
    pointer-events: none;
    font-size: 0.9rem;
    font-weight: 100;
    line-height: 1;
    color: $color-main;
    margin-bottom: 0;
    @include media-query(palm) {
        font-size:0.9rem;
        font-weight: 500;
        line-height: 1.1;
        color: $color-main;
        margin: 9px 0px;
    }
    @include media-query(lap) {
        font-size:0.9rem;
    }
    @include media-query(lap-and-up) {
        opacity: 1;
        line-height: 1.4;
    }
    @include media-query(desk) {
        font-size:0.9rem;
    }
}
@media screen and (max-width: 1023px) and (min-width: 722px){
    .nav-main__grid {
        width: 16.6% !important;
        margin-top: 15px;
    }
}
@include media-query (palm) {
    .nav-main-item-package.is-active .nav-main-circle {
        position: absolute;
        right: -10px;
    }
    .is-active.nav-main-item-experience .nav-main-circle {
        position: absolute;
        right: -4px;
    }
    .is-active .nav-main__icon--flighthotel {
        background-position-x: -7px !important;
    }
}