///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Include vendor prefixes for linear gradients
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: top;
    }
    background: nth(nth($color-stops, 1), 1); // fallback for browsers that don't support linear gradients
    background: linear-gradient(legacy-direction($direction), $color-stops);
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

// Apply ‘attention’ styles in one go.
@mixin attention($self: false) {
    @if ($self==hover) {
        &:hover {
            @content;
        }
    }
    @else if ($self==active) {
        &:active {
            @content;
        }
    }
    @else if ($self==focus) {
        &:focus {
            @content;
        }
    }
    @else if($self !=false) {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
    @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

// Generate Helvetica Neue’s numbered font-weights from their aliases.
@mixin font-weight($font-weight) {
    @if ($font-weight=="regular") {
        font-weight: 300;
    }
    @if ($font-weight=="bold") {
        font-weight: 800;
    }
}

// Create all specified heading styles in one go.
@mixin headings($from: 1, $to: 6) {
    %base-heading {
        @content;
    }
    @if ($from > 0 and $to < 7) {
        @for $i from $from through $to {
            h#{$i} {
                @extend %base-heading;
            }
        }
    }
}

// Set button color for background and border on default and attention states
@mixin btn-color($color-btn, $color-btn-border: $color-btn) {
    background-color: $color-btn;
    border-color: $color-btn-border;
    @if $color-btn !=$color-btn-border {
        border-color: rgba($color-btn-border, .1);
        color: darken($color-btn-border, 15%);
        @include font-weight(bold);
    }
    @include attention() {
        background-color: darken($color-btn, 10%);
        border-color: darken($color-btn-border, 15%);
        @if $color-btn !=$color-btn-border {
            border-color: rgba($color-btn-border, .3);
            color: darken($color-btn-border, 15%);
        }
    }
}

@mixin btn-static($color-btn, $color-txt: $color-btn) {
    background-color: $color-btn;
    border-color: $color-btn;
    @if $color-txt !=$color-btn {
        color: $color-txt;
    }
    @else {
        color: darken($color-btn, 15%);
    }
    cursor: default;
    @include attention() {
        background-color: $color-btn;
        border-color: $color-btn;
        @if $color-txt !=$color-btn {
            color: $color-txt;
        }
        @else {
            color: darken($color-btn, 15%);
        }
    }
}

@mixin btn-inactive($color-border) {
    background-color: transparent;
    border-color: $color-border;
    color: $color-border;
    @include attention() {
        background-color: transparent;
        border-color: darken($color-btn, 15%);
        color: darken($color-btn, 10%);
    }
}


$gradient-submitLight: rgba(30, 87, 153, 1);
$gradient-submitDark: rgba(52, 186, 201, 1);
@mixin blueGradient() {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyJSIgc3RvcC1jb2xvcj0iIzFlNTc5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU3JSIgc3RvcC1jb2xvcj0iIzM0YmFjOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    @include linear-gradient(top, $gradient-submitLight 0%, $gradient-submitDark 57%);
}

$gradient-specialSubmitLight: rgba(231, 19, 19, 1);
$gradient-specialSubmitDark: rgba(163, 12, 12, 1);
@mixin redGradient() {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyJSIgc3RvcC1jb2xvcj0iIzFlNTc5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU3JSIgc3RvcC1jb2xvcj0iIzM0YmFjOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    @include linear-gradient(top, $gradient-specialSubmitLight 0%, $gradient-specialSubmitDark 57%);
}

$gradient-specialAviaturSubmitLight: rgba(30, 87, 153, 1);
$gradient-specialAviaturSubmitDark: rgba(0, 0, 0, 1);
@mixin blackGradient() {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyJSIgc3RvcC1jb2xvcj0iIzFlNTc5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU3JSIgc3RvcC1jb2xvcj0iIzM0YmFjOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    @include linear-gradient(top, $gradient-specialAviaturSubmitLight 0%, $gradient-specialAviaturSubmitDark 57%);
}


// here be a magic grid utility, to use only with kittens and rainbows in the background
@mixin masonry($count: 2, $gap: $inuit-base-spacing-unit) {
    clear: both;
    padding: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        column-gap: $gap;
        column-count: $count;
        & > * {
            -webkit-column-break-inside: avoid;
            /* Chrome, Safari */
            page-break-inside: avoid;
            /* FF */
            break-inside: avoid-column;
        }
    }
}


// apply materialized shadows
$material-1: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
$material-2: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .3);
$material-3: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .3);
$material-4: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .3);
$material-5: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .3);

@mixin material-shadow($depth: 1) {
    $depth-shadow: $material-1;
    @if $depth==1 {
        $depth-shadow: $material-1;
    }
    @else if $depth==2 {
        $depth-shadow: $material-2;
    }
    @else if $depth==3 {
        $depth-shadow: $material-3;
    }
    @else if $depth==4 {
        $depth-shadow: $material-4;
    }
    @else if $depth==5 {
        $depth-shadow: $material-5;
    }
    box-shadow: $depth-shadow;
}

@mixin material-dash-keyframes($ratio) {
    $animation: unquote('dash' + $ratio);
    @include keyframes($animation) {
        0% {
            stroke-dasharray: 1, $ratio;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: ($ratio * .445), $ratio;
            stroke-dashoffset: -($ratio * .175);
        }
        100% {
            stroke-dasharray: ($ratio * .445), $ratio;
            stroke-dashoffset: -($ratio * .62);
        }
    }
}

@mixin material-dash-animation($ratio, $speed: 1.5s) {
    $animation: unquote('dash' + $ratio);
    animation: $animation $speed ease-in-out infinite;
}

@mixin material-loader($size, $speed: 1.5s, $color: $color-main) {
    $dash-speed: $speed - $speed / 4;
    $dash-size: $size * 4;
    @include material-dash-keyframes($dash-size);
    .mat-loader--#{$size} {
        animation: rotate $speed linear infinite;
        height: unquote($size + 'px');
        width: unquote($size + 'px');
        & > .mat-loader--#{$size}__path {
            stroke-dasharray: 1, $dash-size;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            stroke: $color;
            @include material-dash-animation($dash-size, $dash-speed);
        }
    }
}

@mixin material-button() {}
