///*------------------------------------*\
//    #NAV-TABS
//\*------------------------------------*/

//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
    margin-bottom: $inuit-base-spacing-unit;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    @extend .clearfix;

    > li {
        position: relative;
        display: block;

        > a {
            position: relative;
            display: block;
            padding: $inuit-base-spacing-unit--small;
            &:hover,
                &:focus {
                text-decoration: none;
                background-color: $color-nav-hover-bg;
            }
        }
    }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
    margin-left: 0;
    border-bottom: 1px solid $color-nav-border;
    > li {
        float: left;
        // Make the list-items overlay the bottom border
        margin-bottom: -1px;

        // Actual tabs (as links)
        > a {
            margin-right: 2px;
            height: 2.5rem;
            border: 1px solid transparent;
            border-radius: $base-round $base-round 0 0;
            &:hover {
                border-color: transparent
            }
        }

        // Active state, and its :hover to override normal :hover
        &.active > a {
            &,
            &:hover,
                &:focus {
                background-color: transparent;
                border: 1px solid $color-nav-active-border;
                border-bottom-color: #f9f9f9;
                cursor: default;
            }
        }
    }
}



// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
    > .tab-pane {
        display: none;
        visibility: hidden;
    }
    > .active {
        display: block;
        visibility: visible;
    }
}




.nav--light {
    > * {
        @include attention() {
            outline: 0;
            background-color: $gray-plain-light;
        }
    }
    > .active {
        background-color: $gray-plain-light;
    }
}




.nav-option {
    display: inline-block;
    position: relative;
    float: left;
    background-color: $color-frame-bg;
    text-align: center;
    padding: $inuit-base-spacing-unit;
    outline: 0;
    border-radius: 0;
    border-style: solid;
    border-color: $color-frame-border;
    border-width: 1px 0;
    cursor: pointer;
    &:nth-child(n+2) {
        border-left-width: 1px;
    }
    &:first-child {
        border-left-width: 1px;
    }
    &:last-child {
        border-right-width: 1px;
    }
    &:hover, &:focus {
        background-color: #fff;
    }
    &.active {
        background-color: transparent;
        border-bottom-color: transparent;
    }
    &.active:after {
        content: '';
        position: absolute;
        background-color: $color-btn;
        width: 100%;
        height: 4px;
        top: -1px;
        left: 0;
    }
}



.option-toggle {
    &.active {
        font-weight: 600;
        color: $gray-main-light;
    }
    &:not(.active) {
        @extend .link-span;
    }
}

/* Tabs container */
.r-tabs {
    position: relative;
    background-color: #000; /* Old browsers */
    background: #f9f9f9; /* Old browsers */

/*    background: -moz-linear-gradient(top,  #f9f9f9 0%, #e9e9e9 10%, #e9e9e9 100%);  FF3.6+ 

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9f9f9), color-stop(10%,#e9e9e9), color-stop(100%,#e9e9e9));  Chrome,Safari4+ 

    background: -webkit-linear-gradient(top,  #f9f9f9 0%,#e9e9e9 10%,#e9e9e9 100%);  Chrome10+,Safari5.1+ 

    background: -o-linear-gradient(top,  #f9f9f9 0%,#e9e9e9 10%,#e9e9e9 100%);  Opera 11.10+ 

    background: -ms-linear-gradient(top,  #f9f9f9 0%,#e9e9e9 10%,#e9e9e9 100%);  IE10+ */

    background: linear-gradient(to bottom,  #f9f9f9 0%,#e9e9e9 10%,#e9e9e9 100%); /* W3C */

    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e9e9e9',GradientType=0 );  IE6-9 */


    border: 1px solid #E9E9E9;
}
/* Tab element */
.r-tabs .r-tabs-nav .r-tabs-tab {
    position: relative;
    background: #f9f9f9; /* Old browsers */
/*    background: -moz-linear-gradient(top,  #f9f9f9 0%, #eeeeee 100%);  FF3.6+ 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9f9f9), color-stop(100%,#eeeeee));  Chrome,Safari4+ 
    background: -webkit-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  Chrome10+,Safari5.1+ 
    background: -o-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  Opera 11.10+ 
    background: -ms-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  IE10+ */
    background: linear-gradient(to bottom,  #f9f9f9 0%,#eeeeee 100%);  /*W3C */
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#eeeeee',GradientType=0 );  IE6-9 */
    width: 24.7324113%;
    text-align: center; 
}

/* Tab anchor */
.r-tabs .r-tabs-nav .r-tabs-anchor {
    display: inline-block;
    padding: 10px 12px;
    text-decoration: none;
    font-size: 14px;
    color: #666;
    width:100%;
    text-align:center;
    /*font-family:'opensans_bold','Lucida Sans Unicode','Lucida Grande',sans-serif,arial,Verdana,Geneva;*/
}

/* Disabled tab */
.r-tabs .r-tabs-nav .r-tabs-state-disabled {
    opacity: 0.5;
}

/* Active state tab anchor */
.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
    color: #333;
    background-color: #fff;
    width: 100%
}

/* Tab panel */
.r-tabs .r-tabs-panel {
    background-color: white;

    border-bottom: 4px solid white;

    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

}

/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
    display: block;
    padding: 10px;
/*    background: -moz-linear-gradient(top,  #f9f9f9 0%, #eeeeee 100%);  FF3.6+ 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9f9f9), color-stop(100%,#eeeeee));  Chrome,Safari4+ 
    background: -webkit-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  Chrome10+,Safari5.1+ 
    background: -o-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  Opera 11.10+ 
    background: -ms-linear-gradient(top,  #f9f9f9 0%,#eeeeee 100%);  IE10+ */
    background: linear-gradient(to bottom,  #f9f9f9 0%,#eeeeee 100%); /* W3C */
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#eeeeee',GradientType=0 );  IE6-9 */
    color: #666;
    text-decoration: none;
    font-size: 14px;
    /*font-family:'opensans_bold','Lucida Sans Unicode','Lucida Grande',sans-serif,arial,Verdana,Geneva;*/
}
/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
    background: #fff !important;
    color: #1A65AA;
    text-shadow: none;
}
/* Disabled accordion button */
.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {
    opacity: 0.5;
}
// /* Buttons */
// button {
//     display:inline-block;
//     margin-top: 10px;
//     margin-right: 10px;
//     padding: 10px 20px;
//     line-height: 100%;

//     color: #fff;
//     font-size: 14px;
//     text-align: center;
//     text-shadow: 0 1px rgba(0, 0, 0, 0.3);
//     vertical-align: middle;
//     font-weight: bold;

//     border: 0;
//     -webkit-border-radius: 3px;
//     -moz-border-radius: 3px;
//     border-radius: 3px;
//     background-color: #00c5ad;
//     //box-shadow: 0px 3px 0px 0px #00ab94;

//     cursor: pointer;
// }

/* Info bar */
.info {
    display:inline-block;
    margin-top: 5px;
    margin-right: 10px;
    padding: 0;
    width: 300px;
    line-height: 100%;

    /*font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;*/
    color: $color-gree-text;
    @include font-weight(bold);
} 
/* stilos tabs */


.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
}

.r-tabs .r-tabs-panel {
    padding: 15px;
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

/* Accordion responsive breakpoint */
@media only screen and (max-width: 1126px) {
    .r-tabs .r-tabs-nav {
        display: none;
    }

    .r-tabs .r-tabs-accordion-title {
        display: block;
    }
}