///*------------------------------------*\
//    #SEARCH CAR
//\*------------------------------------*/

.search__row-car-city {
    @include media-query(portable) {
        clear: both;
        &:before, &:after {
            clear: both;
            content: " ";
            display: table;
        }        
    }
}
.search__col-car-city {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
.search__col-car-date {
    @include span(3 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
