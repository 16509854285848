/**
 * Xbrowsers direction syntax helpers 
 */

@function is-direction($value) {
    $is-keyword: index((bottom,
    bottom left,
    left bottom,
    left,
    top left,
    left top,
    top,
    top right,
    right top,
    right,
    right bottom,
    bottom right),
    $value);
    $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));
    @return $is-keyword or $is-angle;
}

@function legacy-direction($value) {
    @if is-direction($value)==false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }
    $conversion-map: ( 'bottom': to top,
    'bottom left': to top right,
    'left bottom': to right top,
    'left': to right,
    'top left': to bottom right,
    'left top': to right bottom,
    'top': to bottom,
    'top right': to bottom left,
    'right top': to left bottom,
    'right': to left,
    'right bottom': to left top,
    'bottom right': to top left);
    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }
    @return 90deg - $value;
}

/* *
 * List helpers 
 */

@function first($list) {
    @return nth($list, 1);
}

@function last($list) {
    @return nth($list, length($list));
}

@function last-index($list, $value) {
    @for $i from length($list) *-1 through -1 {
        @if nth($list, abs($i))==$value {
            @return abs($i);
        }
    }
    @return null;
}

@function prepend($list, $value) {
    @return join($value, $list);
}

@function insert-nth($list, $index, $value) {
    $result: null;
    @if type-of($index) !=number {
        @warn "$index: #{quote($index)} is not a number for `insert-nth`.";
    }
    @else if $index < 1 {
        @warn "List index 0 must be a non-zero integer for `insert-nth`";
    }
    @else if $index > length($list) {
        @warn "List index is #{$index} but list is only #{length($list)} item long for `insert-nth'.";
    }
    @else {
        $result: ();
        @for $i from 1 through length($list) {
            @if $i==$index {
                $result: append($result, $value);
            }
            $result: append($result, nth($list, $i));
        }
    }
    @return $result;
}

@function replace($list, $old-value, $new-value, $recursive: false) {
    $result: ();
    @for $i from 1 through length($list) {
        @if type-of(nth($list, $i))==list and $recursive {
            $result: append($result, replace(nth($list, $i), $old-value, $new-value, $recursive));
        }
        @else {
            @if nth($list, $i)==$old-value {
                $result: append($result, $new-value);
            }
            @else {
                $result: append($result, nth($list, $i));
            }
        }
    }
    @return $result;
}

@function replace-nth($list, $index, $value) {
    $result: null;
    @if type-of($index) !=number {
        @warn "$index: #{quote($index)} is not a number for `replace-nth`.";
    }
    @else if $index==0 {
        @warn "List index 0 must be a non-zero integer for `replace-nth`.";
    }
    @else if abs($index) > length($list) {
        @warn "List index is #{$index} but list is only #{length($list)} item long for `replace-nth`.";
    }
    @else {
        $result: ();
        $index: if($index < 0, length($list) + $index + 1, $index);
        @for $i from 1 through length($list) {
            @if $i==$index {
                $result: append($result, $value);
            }
            @else {
                $result: append($result, nth($list, $i));
            }
        }
    }
    @return $result;
}

@function remove($list, $value, $recursive: false) {
    $result: ();
    @for $i from 1 through length($list) {
        @if type-of(nth($list, $i))==list and $recursive {
            $result: append($result, remove(nth($list, $i), $value, $recursive));
        }
        @else if nth($list, $i) !=$value {
            $result: append($result, nth($list, $i));
        }
    }
    @return $result;
}

@function remove-nth($list, $index) {
    $result: null;
    @if type-of($index) !=number {
        @warn "$index: #{quote($index)} is not a number for `remove-nth`.";
    }
    @else if $index==0 {
        @warn "List index 0 must be a non-zero integer for `remove-nth`.";
    }
    @else if abs($index) > length($list) {
        @warn "List index is #{$index} but list is only #{length($list)} item long for `remove-nth`.";
    }
    @else {
        $result: ();
        $index: if($index < 0, length($list) + $index + 1, $index);
        @for $i from 1 through length($list) {
            @if $i !=$index {
                $result: append($result, nth($list, $i));
            }
        }
    }
    @return $result;
}

@function slice($list, $start: 1, $end: length($list)) {
    $result: null;
    @if type-of($start) !=number or type-of($end) !=number {
        @warn "Either $start or $end are not a number for `slice`.";
    }
    @else if $start > $end {
        @warn "The start index has to be lesser than or equals to the end index for `slice`.";
    }
    @else if $start < 1 or $end < 1 {
        @warn "List indexes must be non-zero integers for `slice`.";
    }
    @else if $start > length($list) {
        @warn "List index is #{$start} but list is only #{length($list)} item long for `slice`.";
    }
    @else if $end > length($list) {
        @warn "List index is #{$end} but list is only #{length($list)} item long for `slice`.";
    }
    @else {
        $result: ();
        @for $i from $start through $end {
            $result: append($result, nth($list, $i));
        }
    }
    @return $result;
}

@function reverse($list, $recursive: false) {
    $result: ();
    @for $i from length($list) *-1 through -1 {
        @if type-of(nth($list, abs($i)))==list and $recursive {
            $result: append($result, reverse(nth($list, abs($i)), $recursive));
        }
        @else {
            $result: append($result, nth($list, abs($i)));
        }
    }
    @return $result;
}

@function to-string($list, $glue: '', $is-nested: false) {
    $result: null;
    @for $i from 1 through length($list) {
        $e: nth($list, $i);
        @if type-of($e)==list {
            $result: $result#{to-string($e, $glue, true)};
        }
        @else {
            $result: if($i !=length($list) or $is-nested, $result#{$e}#{$glue}, $result#{$e});
        }
    }
    @return $result;
}

@function shift($list, $value: 1) {
    $result: ();
    @for $i from 0 to length($list) {
        $result: append($result, nth($list, ($i - $value) % length($list) + 1));
    }
    @return $result;
}
