///*------------------------------------*\
//    #PAYOUT-EXTRAS/ADDITIONALS
//\*------------------------------------*/

.paymentsCustomer__container {
    display: table;
    position: relative;
    width: 100%;
    min-height: 50px;
    padding: 5px;
    border: 1px solid #eee;
    /*border-bottom-color: transparent;*/
    table-layout: fixed;
    @include media-query(palm) {
        width: calc(100% + #{$inuit-base-spacing-unit*2});
        margin-left: -$inuit-base-spacing-unit;
        margin-right: -$inuit-base-spacing-unit;
    }

    & > div {
        display: table-cell;
        vertical-align: middle;
    } 
}

.add-margin-bottom{
    padding-bottom: 15px;
}

.add-margin-top{
    padding-top: 15px;
}
.destacable-info-request{
    font-size: 17px;
}

.card-item-info{
    width: 60%;
    vertical-align: bottom !important;
}

.img-franchise{
    margin-left: 15px;
    margin-right: 18px;
    float: left;
}

.text-card-number{
    font-size: 15px;
}

.no-option-label{
    margin-left: 15px;
    font-size: 14px;
}

.select-card-button{
    margin-left: 18px;
}