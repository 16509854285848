///*------------------------------------*\
//    #DETAIL-CAR
//\*------------------------------------*/

/* VARS */
$open-sans: 'Open Sans', 'Helvetica', sans-serif;

$darkNavy: #213140;
$teal1: #66B3FB;
$teal2: #4B9DEA;
$charcoal: #555555;
$gold: #B6985A;

$activeShadow: 0 0 10px rgba($teal1, .5);

/* MIXINS */
@mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
@mixin breakpoint($point) {
    @if $point == 1100 {
        @media (max-width: 1100px) { @content ; }
    }
    @else if $point == 800 {
        @media (max-width: 800px) { @content ; }
    }
}

.detail-car__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}


// Detail sidebar //

.detail-car__sidebar {
    @include media-query(desk) {
        @include span(2 of 6);
    }
}
.detail-car__frame-inner-wrapper {
    font-size: 15px;
    /*    margin-bottom: $inuit-base-spacing-unit;*/
}
.detail-car__title {
    width: 100%;
    margin-bottom: $inuit-base-spacing-unit--small;
    text-align: center;
}
.detail-car__image {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 30%;
    @include media-query(palm) {
        max-width: 100%;
    }
}
.detail-car__image_list {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 100%;
    margin-left: 20%;
    @include media-query(palm) {
        margin-left: 10%;
    }    
}

.detail-car__image_list_europ {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 100%;    
    @include media-query(palm) {
        margin-left: 10%;
    }    
}

.detail-car__image-wrapper {
    text-align: left;
    max-height: 20%
}
.detail-car__characteristics-data span {
    vertical-align: middle;
}
.detail-car__characteristics-data img {
    width: 16px;
    height: 16px;
}
.detail-car__characteristics {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 3px;
    margin-bottom: 1% !important;
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}
.detail-car__description {
    padding-bottom: $inuit-base-spacing-unit;     
}

.detail-car__description__requeriments {
    padding-bottom: $inuit-base-spacing-unit;     
    font-size: 13px;    
}

.detail-car__description-expanded {
    display: none;
    margin-bottom: $inuit-base-spacing-unit;    
}
.detail-car__price {
    color: white;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
.detail-car__price-frame {
    margin-top: -1px;
}
@include media-query(palm) {
    .detail-car__price-frame--mobile {
        border-bottom: 1px solid rgba(255,255,255,.5) !important;
        border-radius: 0 0 5px 5px !important;
    }
}
.detail-car__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
.detail-car__label {
    font-size: 12px;
}
.detail-car__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-car__characteristics-label {
    display: inline-block;
    width: 25%;
    float: left;
    margin-top: 4px;
    font-size: 11px;
    word-break: break-word;
}
.detail-car__characteristics-info {
    display: inline-block;
    width: 75%;
    font-size: 15px;
}
.detail-car__logo {
    text-align: center;
}
.detail-car__provider {
    width: 100%;
    text-align: center;
}
.detail-car__destination-title {
    margin-bottom: 5px;
    font-size: 16px;
    @include media-query(lap) {
        text-align: center;
    }
}
.detail-car__itinerary-info {
    margin-bottom: 4px;
    @include media-query(lap) {
        text-align: center;
    }
}
.detail-car__image-map {
    max-width: 640px;
    height: 120px;
    margin: 0 auto;
    border: 2px solid rgba(0,0,0,0);
    border-radius: $base-round;
    cursor: pointer;
    @include attention() {
        border-color: rgba(0,0,0,.1);
    }
}
.detail-car__rate-price {
    width: 50%;
    float: left;    
    text-align: center;
}
.detail-car__rate-price-extra {
    width: 100%;
    float: left;    
    text-align: center;
}
.detail-car__rate-detail {
    width: 100%;
}
.detail-car__more {
    display: none;
    margin-top: $inuit-base-spacing-unit--small;
}
.detail-car__more-details {
    margin-bottom: 0; 
}
.detail-car--submit-price {
    @include span(1 of 2);
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        @include span(1 of 3);
    }
}
.detail-car--submit-btn {
    @include span(1 of 1);
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        @include span(1 of 3);
    }
}

.deleteX{
    box-shadow: 1px 1px 1px 2px;
}

.frame-view{
    padding: 5px 20px;
    background: rgba(0, 155, 248, 0.2);
    border-radius: 0 0 20px 20px;
    cursor: pointer;    
    text-align: center;
    width: 60%;
    margin-left: 40%;
    @include media-query(palm) {
        width: 101%;        
        margin-left: 0%;
    }
}

.frame-infoCars{
    border-radius: 10px;
}

.detail-car_information {       
    margin-left: 30%;    
    margin-bottom: 10%;

}

.detail-car_information_services{
    padding-bottom: 16px;    
    margin-left: 25%;    
}

.grid-price{
    width: 40%;
    padding-left: 3%;
    @include media-query(palm) {
        width: 35%;
    }
}

.grid-cars-selected{
    width: 70%;
}
.grid-cars-view{
    width: 30%;
}

.grid-price-resume{
    width: 50% !important;
    @include media-query(palm) {
        width: 100% !important;        
    }
}

.hide-element{
    margin-left: 2%;
    @include media-query(palm) {
        margin-left: 5%;
    }
}

.detail-car__option {
    border-radius: $base-round;
    padding-bottom: 1px !important;
    margin-right: $inuit-base-spacing-unit--small;
    &:hover {
        background-color: lighten($color-main, 45%);
    }
    &:active,
        &.active {
        background-color: lighten($color-main, 40%);
    }
}
.detail-car__option-label {
    margin-right: $inuit-base-spacing-unit--small;
    line-height: 1.9em;
}

.detail-car_intinerary-grid {
    width:100% !important;
}

.selectedCar{    
    box-shadow: 1px 3px 5px 10px #225594;
}

.dispo-car__selected-label{
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 9px;
    border-radius: 0 0 0 5px;
    color: #fff;
    font-weight: 600;
    background-color: #2b7091;
}

.dispo-cars__submit-label{
    font-size: 15px;
}

.dispo-car_ribbon {
    position: absolute;
    right: -4px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.dispo-car_ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#1e5799 0%, #34bac9 57%);  
    position: absolute;
    top: 19px;
    right: -21px;
}
.dispo-car_ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #34bac9;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}
.dispo-car_ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #34bac9;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}

/* CONTAINERS */

.detail-car-container {
    max-width: 850px; 
    width: 100%; 
    margin: 0 3% auto;
    @include media-query(palm) {
        margin: 0 8% auto;
    }    
}

.four { 
    width: 30%; 
    max-width: 30%;
}


/* COLUMNS */

.col-car {
  display: block;
  float:left;
  margin: 1% 0 1% 1.6%;
}

.col-car:first-of-type { 
    margin-left: 0; 
}

/* CLEARFIX */

.cf:before,
.cf:after {    
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

/* FORM */

.form-car-detail .plan input, .form .payment-plan input, .form .payment-type input{
	display: none;
}

.form-car-detail label{
	position: relative;
	color: #fff;
	background-color: #aaa;
	font-size: 15px;
	text-align: center;
	height: 10px;
	line-height: 15px;
	display: block;
	cursor: pointer;
	border: 3px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
        @include media-query(palm) {
            font-size: 10px;
        }        
}

.form-car-detail .plan input:checked + label, .form .payment-plan input:checked + label, .form-car-detail .payment-type input:checked + label{
	border: 1px solid #333;
	background-color: #009bf8;
}

.form-car-detail .plan input:checked + label:after, form .payment-plan input:checked + label:after, .form .payment-type input:checked + label:after{	
	content: "\2713";
	width: 20px;
	height: 20px;
	line-height: 15px;
	border-radius: 100%;
	border: 2px solid #333;
	background-color: #225594;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}

.dispo-selectionMultiple{        
    font-size: 15px;
    @include media-query(palm) {                
        font-size: 10px;
    }
}

