
///*------------------------------------*\
//    #DISPO-HOTEL
//\*------------------------------------*/

// Internal variable
$height-big:     184px;
$height-small:   140px;

.js-hotel-block.recommendation{ 
    padding: 0px !important; 
} 
.dispo-hotel h3{ 
    color: $color-gree-text; 
    margin-top: 0.8rem; 
    margin-bottom: 0rem; 
} 
.dispo-hotel__header {
    margin-bottom: $inuit-base-spacing-unit--small;
    padding: 10px 0;
    @include media-query (palm) {
        margin: 5px;
    }
}
.dispo-hotel__star-rating {
    display: inline-block;
}
.dispo-hotel__rating-title {
    float: left;
    display: inline-block;
    padding: $inuit-base-spacing-unit 0;
    margin-right: $inuit-base-spacing-unit;
    @include media-query(palm){
        margin-right: $inuit-base-spacing-unit--tiny;
    }
}
.dispo-hotel__rating-disclaimer {
    min-width: 300px;
    margin-left: $inuit-base-spacing-unit--small/2.0;
}
.dispo-hotel__rating-stars {
    float: left;
    display: inline-block;
    background: url(/assets/common_assets/img/icon/star_off.svg);
    background-size: contain;
    display: inline-block;
    font-size: 0;
    height: 45px;
    overflow: hidden;
    position: relative;
    width: 225px;
    white-space: nowrap;
    @include media-query(palm){
        margin-bottom: $inuit-base-spacing-unit;
    }
}
/*.starRating-title {
    color: $color-main;
    padding: 10px 0 0 15px;
    margin: 0;
}*/

.dispo-hotel__rating-label {
    margin: 0;
    color: $color-main;
    font-size: 13px;
    background: none;
    height: 100%;
    left: 0;
    line-height: 48px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20%;
    z-index:  $z-index-hotel-rating-label;
}
.dispo-hotel__rating-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    margin: 0 !important;
    opacity: 0;
    padding: 0;
    position: relative;
    width: 20%;
    z-index: $z-index-hotel-rating-input;

}
.dispo-hotel__rating-input:hover, .dispo-hotel__rating-input:checked {
    margin: 0 !important;
}
.dispo-hotel__rating-input:checked + .dispo-hotel__rating-label {
    background: url(/assets/common_assets/img/icon/star_on.svg);
    background-size: contain;
}
.dispo-hotel__rating-input:hover + .dispo-hotel__rating-label {
    opacity: .5;
}
.dispo-hotel__rating-input:active + .dispo-hotel__rating-label {
    opacity: 1;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 20%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 40%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 60%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 80%;
}
/*@media screen and (min-width: 750px) {
    .starRating-title {
        padding-top: 15px;
    }
}*/



.frame-group .frame.recommendation {
    margin-bottom: $inuit-base-spacing-unit;
}

.dispo-hotel__left-column {
    @include span(2 of 3);
    @include media-query(palm){
        float: none;
    }
}

.dispo-hotel__right-column {
    @include span(1 of 3 first);
}

.dispo-hotel__title {
    color:#444;
    margin-bottom: 0;
    @include font-weight(bold);
    @include media-query(palm){
        width: 100%;
        margin-bottom: $inuit-base-spacing-unit--small;
        text-align: center;
        @include font-size(18px);
    }
}
.dispo-hotel__stars {
    filter: contrast(2) hue-rotate(200deg) saturate(1.5);
    height:15px;
    margin-left: $inuit-base-spacing-unit--small;
    margin-bottom: 2px;
    vertical-align: baseline;
    @include media-query(palm){
        height: $inuit-base-spacing-unit;
    }
}

.dispo-hotel__col-image {
    @include media-query(palm){
        width: 100%;
        padding-bottom: 25px;
    }
}

.dispo-hotel__image {
    border-radius: 5px 0px 0px 5px; 
    background: url(/assets/aviatur_assets/img/error/noHotelPicture.jpg) no-repeat center center; 
    background-size: cover; 
    height: calc(15rem + 2rem); 
    margin: 0; 
    @include media-query(palm){ 
        height:calc( 250px - 9px); 
        max-width: 100% ; 
        margin: 0 auto; 
    } 
} 

.dispo-hotel__detail {
    @include font-size(12px);
    @include media-query(palm){
        margin: 0;
        padding: 0;
        font-size: 0;
    }
}

.dispo-hotel__blue {
    width: calc(66.66667% - 30px);
    position: relative;
    display: inline-block;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    // @include blueGradient();
    // box-shadow: inset 0 40px 60px -10px rgba(0, 0, 0, .2);
    @include media-query(palm){
        width: calc(100% - 2*#{$inuit-base-spacing-unit});
        width: 100%;
        padding-bottom: $inuit-base-spacing-unit--small;
    }
    @include media-query(lap-and-up){
        padding: $inuit-base-spacing-unit--small;
    }

    & .type-main {
        color: #fff;
    }
}

.dispo-hotel__price {
    color: $color-gree-text;
    @include span(2 of 3);
    @include media-query(palm){
        @include span(full);
    }
}

.dispo-hotel__price-label {
    @include font-size(14px);
    @include media-query(palm){
        @include font-size(11px);
        font-size: 0.9rem;
        text-align: center;
    }
}
.dispo-hotel__price-value {
    color: $color-gree-text;
    font-size: 28px;
    @include font-weight(bold);
}

.dispo-hotel__submit {
    @include span(1 of 3);
    @include media-query(lap-and-up){
        margin: $inuit-base-spacing-unit--small 0;
    }
    @include media-query(palm){
        @include span(full);
    }
}

.dispo-hotel__price-label_promo {
    color: #fff;
    padding: 0 $inuit-base-spacing-unit--small;
    @include font-size(14px);
    @include media-query(palm){
        @include font-size(11px);
        text-align: center;
        color: #fff;
        font-size:10px;
    }
}

.dispo-hotel__price-label_promo-yell {
    color: #fff;
    text-transform: uppercase;
}

.dispo-hotel__price-value_promo {
    color: #fff;
    font-size: 14px;
    @include font-weight(bold);
}

.dispo-hotel__show-map {
    float: left;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    padding: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up){
        width: 100%;
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.dispo-hotel__show-map .icon_contact_address {
    margin-top: -4px;
}
@include media-query(palm){
    .dispo-hotel__star-rating,
    .dispo-hotel__show-map {
        width: 100%;
        display: inline-block;
    }
}


/* Map -> Price filter */
.dispo-hotel__map-container .map__price-filter {
    position: absolute;
    left: 0;
    display: inline-block;
    visibility: hidden;
    @include media-query(palm) {
        bottom: 0;
        width: 100%;
    }
    @include media-query(lap-and-up) {
        top: $inuit-base-spacing-unit--small;
        left: $inuit-base-spacing-unit--small;
    }
}
.dispo-hotel__map-container .map__price-filter > * {
    z-index: 5;
}
.dispo-hotel__map-container .map__price-filter .map__price-range {
    position: relative;
    font-weight: 600;
    padding: $inuit-base-spacing-unit--small;
    background-color: #fbfbfb;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 2px;
    @include media-query(palm) {
        padding-top: round($inuit-base-spacing-unit * 2);
    }
    @include media-query(lap-and-up) {
        margin-bottom: 3px;
        border-color: $color-frame-border;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .3);
    }
}
.dispo-hotel__map-container .map__price-filter .map__price-range .map__price-label {
    font-size: 13px;
}
.dispo-hotel__map-container .map__price-filter .map__price-range .map__price-amount {
    color: $color-dispo-filter;
    font-size: $inuit-base-font-size;
    font-weight: 600;
    margin-left: $inuit-base-spacing-unit--small;
}
.dispo-hotel__map-container .map__price-filter .map__slider-range {
    display: inline-block;
    &.ui-slider-horizontal { // 'palm'
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        margin-top: $inuit-base-spacing-unit;
        & .ui-slider-range {
            border-color: rgba(0,0,0,.2);
        }
        & .ui-slider-handle {
            width: 1.5em;
            margin-left: -.75em;
            border-color: rgba(0,0,0,.3);
        }
    }
    &.ui-slider-vertical { // 'lap-and-up'
        float: left;
        width: 1em;
        height: 250px;
        left: round($inuit-base-spacing-unit--small * 3 - 5);
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .3);
        & .ui-slider-handle {
            width: 1.5em;
            border-color: #c6c6c6;
        }
    }
}

/* Map -> Price InfoBox */
.dispo-hotel__map-container #map .ib_hotel {
    position: relative;
}
.dispo-hotel__map-container #map .ib_pointer {
    position: absolute;
    bottom: -29px;
    width: 5px;
    height: 6px;
    overflow: hidden;
    z-index: 2;
    &.left-side {
        left: 15px;
    }
    &.left-side > * {
        transform: skewX(45deg);
        transform-origin: 0 0 0;
        border-left: 1px solid $color-frame-border;
    }
    &.right-side {
        left: 20px;
    }
    &.right-side > * {
        transform: skewX(-45deg);
        transform-origin: 5px 0 0;
        border-right: 1px solid $color-frame-border;
    }
    &.left-side > *,
    &.right-side > * {
        width: inherit;
        height: inherit;
        background-color: #fbfbfb;
        box-shadow: 0 1px 3px rgba(0,0,0,.6);
    }
}
.dispo-hotel__map-container #map .ib_hotel-price {
    position: absolute;
    padding: 5px;
    border: 1px solid $color-frame-border;
    border-radius: 2px;
    background-color: #fbfbfb;
    box-shadow: 0 1px 2px -1px rgba(0,0,0,.6);
}

/* Map -> Rotating Marker */
.dispo-hotel__map-container #map .rotate-marker {
    position: absolute;
    width: 26px;
    height: 38px;
    cursor: pointer;
    background: url("/assets/common_assets/img/icon/marker-point.svg") no-repeat center;
    animation: rotate-y 2s infinite;
}

/* Map -> Hotel InfoWindow */
.dispo-hotel__map-container #map .iw_hotel {
    max-width: 255px;
}
.dispo-hotel__map-container #map .iw_hotel,
.dispo-hotel__map-container #map .ib_hotel-price {
    font-family: 'Open Sans';
}
.dispo-hotel__map-container #map .iw_hotel > *:not(:last-child) {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.dispo-hotel__map-container #map .iw_hotel-name {
    font-weight: 600;
}
.dispo-hotel__map-container #map .iw_hotel-stars {
    display: block;
    margin: 0 auto;
}
.dispo-hotel__map-container #map .iw_hotel-img {
    max-width: 255px;
}
.dispo-hotel__map-container #map .iw_hotel-price span.float-left {
    float: left;
    margin-right: $inuit-base-spacing-unit--small;
} 
h3.dispo-hotel__title div.tooltips{ 
    @include media-query(palm){ 
        display: block; 
    } 
} 
.dispo-flight__filter {
    h5 {
        color: #444;
        font-weight: bold;
        @include media-query (palm) {
            color: $color-main;
            font-weight: normal;
        }
    }
    @include media-query (palm) {
        overflow-y: scroll;
        padding: 10px;
    }
}
.dispo-hotel .btn--submit{
  box-shadow: none;
}
span.badge { 
    min-width: 1.4rem; 
    padding: 2px 4px; 
    text-align: center; 
    font-size: 1rem; 
    line-height: 20px; 
    height: 22px; 
    color: #757575; 
    float: left; 
    box-sizing: border-box; 
} 
span.badge.new { 
    font-weight: bold; 
    font-size: 0.8rem; 
    color: #fff; 
    background-color: $color-main; 
    border-radius: 2px; 
} 
span.badge.breakfast { 
    font-weight: bold; 
    font-size: 0.7rem; 
    color: #666; 
    background-color: white; 
    border-radius: 20px; 
    height: 30px; 
    line-height: 25px; 
} 
.hotel-icon-text{ 
    font-size:0.8rem; 
    height: 30px; 
    padding-top: 5px; 
    padding-left: 0px; 
} 
.dispo-hotel__filter-select { 
    .selectFilterPrice { 
        padding: 8px; 
        height: 40px; 
        border-radius: 6px; 
        border: thin solid #bbbbbb; 
        background-color: white !important; 
        margin-bottom: 5px; 
        @include media-query (palm) {
            width: 100%;
            border: none;
            box-shadow: 0px 4px 4px #c7c4c4;
            &.select--arrow {
                background-size: 15px;
                background-blend-mode: difference;
            }
        } 
    } 
    label { 
        padding: 11px 0 11px 15px; 
        font-size: 16px; 
        color: $color-gree-text; 
        font-weight: 700;  
        @include media-query (palm) { 
            padding-left: 2px; 
            margin-top: 4px;
            font-size: 20px;
            color: $color-main;
        } 
    } 
    @include media-query (palm) {
        padding: 15px;
    } 
} 
.camera { 
    width: 10%; 
    position: absolute; 
    bottom: 3px; 
    right: 31px; 
    // visibility: hidden; 
    // opacity: 0; 
    transition: opacity 0.6s ease; 
    cursor: pointer; 
    svg { 
        background-color: rgba(0,0,0,0.5); 
        padding: 7px;  
        border-radius: 9px; 
    } 
} 
.modalImagesHotel { 
    visibility: hidden; 
    opacity: 0; 
    position: fixed; 
    left: 0%; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    background-color: rgba(0,0,0,.5); 
    transition: opacity 1s ease; 
    z-index: 100; 
    margin-bottom: 0 !important; 
    .modalContent { 
        top: 7%; 
        width: 60%;  
        height: 518px; 
        margin: auto !important; 
        padding: 0px; 
    }  
    .btn-closeModal { 
        position: absolute; 
        right: -12px; 
        top: -12px; 
        border-radius: 16px; 
        border: 0; 
        background-color: white; 
        padding: 6px 7px; 
        color: $color-main; 
        font-size: 13px; 
        z-index: 1; 
        .icon_search_close{ 
            font-weight: bold; 
            &:before { 
                vertical-align: text-top; 
            } 
        } 
        @include attention () { 
            background-color: lighten($color-main, 50) !important; 
            .icon { 
                color: $color-main; 
            } 
        } 
    } 
} 
 
.active-modal, .camera-active { 
    visibility: visible; 
    opacity: 1; 
} 
 
.owl-hotel {
    .owl-prev,  
    .owl-next { 
        background-color: #fff !important; 
        color: #666 !important; 
        margin: 25px !important; 
        @include attention() { 
            background-color: #fff !important; 
            color: #444 !important; 
        } 
    } 
}
.js-owl-hotel { 
    .dispo-hotel__image { 
        height: 429px; 
        border-radius: 0; 
    } 
    .owl-prev, 
    .owl-next { 
        background-color: white !important; 
        border: 1px solid #9fc2e4; 
        padding: 14px 15px !important; 
        .icon { 
            color: $color-main; 
        } 
        @include attention () { 
            background-color: lighten($color-main, 50) !important; 
        } 
    } 
} 
.hotel-filter__stars { 
    .material-icons-round { 
        margin-left: -5px; 
    } 
    input[type='checkbox']{ 
        margin-top: 8px; 
    } 
} 
.modal-listImages { 
    margin-left: 0; 
    margin-bottom: 0; 
    padding: 5px 7px 7px; 
    li { 
        cursor: pointer; 
    } 
} 
.modal-images__hotel { 
    height: 4em; 
    width: 13%; 
    background-size: cover; 
    background-repeat: no-repeat; 
    display: inline-block; 
    margin-right: 6px; 
    margin-top: 7px; 
} 
.submitFormModa { 
    position: absolute; 
    bottom: 5%; 
    right: 2%; 
    width: 29.2%;   
} 
.hotel-price-detail{  
    text-align: right; 
    @include media-query (palm) { 
      text-align: left; 
    } 
} 

.js-hotel-block.recommendation {
    padding: 0px !important;
}
#resumeSearchHotel {
    @include media-query(palm) {
        margin-bottom: 0;
    }
}
.title-filterBy {
    position: absolute;
    top: 4%;
    left: 4%;
    font-size: 20px;
    color: #065eb8;
    font-weight: bold;
}
.content-filter__mobile {
    @include media-query (palm) {
        top: 9%;
        left: 0;
        right: 0;
        position: absolute;
        background-color: #f1f1f1;
        padding: 12px 10px;
        border-radius: 9px;
    }
}
.hotel-input-filter {
    @include media-query (palm) {
        background-color: white !important;
        color: #969696 !important;
        font-weight: 700 !important;
        border-radius: 8px !important;
    }
}
.filer-feeding {
    @include media-query (palm) {
        input[type=radio] {
            all: unset;
            position: absolute;
            width: 4.4%;
            height: 1.5%;
            border: 2px solid #065eb8;
            border-radius: 16px;
            left: 5%;
            margin-top: -2px;
            &::before {
                content: '';
                display: block;
                height: 10px;
                width: 10px;
                background: #065eb8;
                border-radius: 100%;
                position: absolute;
                z-index: 1;
                top: 3px;
                left: 3px;
                visibility: hidden;
            }
        }
        input[type=radio]:checked {      
            border: 3px solid #065eb8;
            &::before {
                visibility: visible;
            }
        }        
        @include media-query (palm) {
            label {
                margin-left: 7%;
                font-weight: bold;
            }
        }
    }
}
.filer-services, .hotel-filter__stars {
    @include media-query (palm) {
        input[type=checkbox] {
            all: unset;
            position: absolute;
            width: 3.5%;
            height: 1.2%;
            border: 2px solid #065eb8;
            border-radius: 6px;
            left: 6%;
            &::before {
                content: '';
                display: block;
                height: 10px;
                width: 72%;
                background: #065eb8;
                border-radius: 2px;
                position: absolute;
                z-index: 1;
                top: 1.8px;                
                margin-left: 2px;   
                visibility: hidden;     
            }
        }
        input[type=checkbox]:checked {      
            border: 3px solid #065eb8;
            &::before {
                visibility: visible;
            }
        }  
        label {
            margin-left: 7%;
            font-weight: bold;
        }
    }
}
.filterUnit {
    color: #969696;    
    font-weight: bold;
    @include media-query (desk) {
        padding: 0;
    }
    .maxPrice {
        font-size: 14px;
        text-align: end;
        padding-right: 2px;
        @include media-query (desk) {
            padding: 0;
            font-size: 12px;
        }
    }
    .minPrice {
        font-size: 14px;
        padding-left: 2px;
        @include media-query (desk) {
            padding: 0;
            font-size: 12px;
        }
    }
    .dispo-flight__filter__slider {
        clear: both;
        margin-top: 26px;
    }
}
.spanPrice {
    @include media-query (palm) {
        margin-left: 10px;
    }
    margin-left: 2px;
    color: $color-main;
    font-weight: normal;
}