///*------------------------------------*\
//    #GLOBAL
//\*------------------------------------*/


$brand-round:   5px;
$base-round:    $brand-round;

$z-index--20:   -20;
$z-index--10:   -10;
$z-index-0:     0;
$z-index-10:    10;
$z-index-20:    20;
$z-index-30:    30;
$z-index-40:    40;
$z-index-50:    50;
$z-index-60:    60;
$z-index-70:    70;
$z-index-80:    80;
$z-index-90:    90;
$z-index-100:   100;
$z-index-1040:  1040;
$z-index-1045:  1045;
$z-index-1080:  1080;





$z-index-loading-world__inset:     $z-index--10;
$z-index-luna:                     $z-index--10;

$z-index-loading-world:            $z-index-10;
$z-index-hotel-rating-label:       $z-index-10;
$z-index-icon-input:               $z-index-10;
$z-index-slider-range:             $z-index-10;
$z-index-train-rating-label:       $z-index-10;

$z-index-hotel-rating-input:       $z-index-20;
$z-index-frame-followed:           $z-index-20;
$z-index-slider-handle:            $z-index-20;
$z-index-btn-group__btn-hover:     $z-index-20;
$z-index-input-group__text-input:  $z-index-20;
$z-index-train-rating-input:       $z-index-20;

$z-index-tooltips:                 $z-index-50;

$z-index-slidepanel__header:       $z-index-80;
$z-index-fixed:                    $z-index-80;
$z-index-autocomplete:             $z-index-80;
$z-index-datepicker:               $z-index-1045;
$z-index-slidepanel:               $z-index-80;
$z-index-datepicker-container:     $z-index-80;

$z-index-datepicker-arrow:         $z-index-90;

$z-index-loading-overlay:          $z-index-100;

$z-index-modal:                    $z-index-1040;

$z-index-datepicker-modal:         $z-index-1080;