.offer__container {
    text-align: center;
}

.offer__content {
    display: inline-block;
    margin: 2px;
    margin-bottom: 20px;
}

@media screen and (min-width: 387px) and (max-width: 420px){
    .offer__content {
        width: 340px !important;
    }
}

@media screen and (min-width: 362px) and (max-width: 381px){
    .offer__content {
        width: 320px !important;
    }

}

@media screen and (min-width: 300px) and (max-width: 361px){
    .offer__content {
        width: 100% !important;
    }

}

.offer__title {
    padding-bottom: $inuit-base-spacing-unit--small/2;
    border-bottom: 1px solid $color-frame-border;
    font-size: 16px;
    color: $color-main;
}

.offer__price-label {
    padding: $inuit-base-spacing-unit--small 0;
    border-top: 1px solid #FAFAFC;
    color: rgba(0,0,0,.6);
}

.offer__price {
    @include font-weight(bold);
    color: $color-main;
}

.offer__product-count {
    padding: $inuit-base-spacing-unit--small 0;
    border-bottom: 1px solid #FAFAFC;
    color: rgba(0,0,0,.6);
}

.offer__reservation {
    border-top: 1px solid $color-frame-border;
    text-transform: uppercase;
    color: $color-main;
}
