/*------------------------------------*\
    #RESPONSIVE JS
\*------------------------------------*/

.responsive-js {
    display: none;
    @include media-query(palm) {
        content: "palm";
    }
    @include media-query(lap) {
        content: "lap";
    }
    @include media-query(desk) {
        content: "desk";
    }
}