/*------------------------------------*\
    #CONTACT SLIDER
\*------------------------------------*/

$contact-slider-width: 400px;
$contact-slider-bck-clr: #fff;
$contact-slider-border: 2px solid $color-main;

.contact-slider {
    position: fixed;
    top: $inuit-base-spacing-unit--huge;
    right: 0;
    z-index: $z-index-slidepanel - 1; // allow other slidepanels to appear on top
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}

.contact-slider__banner {
    float: left;
    position: absolute;
    top: $inuit-base-spacing-unit;
    left: -114px;
    width: 185px;
    padding: 12px;
    margin-top: $inuit-base-spacing-unit--huge;
    border: $contact-slider-border;
    border-top-width: 0;
    border-radius: 0 0 $base-round $base-round;
    color: $contact-slider-bck-clr;
    background-color: $color-main;
    @include font-weight(bold);
    box-shadow: 0 2px 3px rgba(64, 64, 64, .4);
    transform: rotate(90deg);
    & .icon {
        padding-right: $inuit-base-spacing-unit--small;
    }
}

.contact-slider__inner {
    float: left;
    width: $contact-slider-width;
    max-width: $contact-slider-width;
    margin-right: -$contact-slider-width;
    padding: $inuit-base-spacing-unit--small;
    border: $contact-slider-border;
    border-right-width: 0;
    border-radius: $base-round 0 0 $base-round;
    background-color: $contact-slider-bck-clr;
    box-shadow: -3px 0 3px rgba(64, 64, 64, .4);
}

.contact-slider__col-ind {
    @include span(1 of 3);
}
.contact-slider__col-num {
    @include span(2 of 3);
}
.contact-slider__col-name,
.contact-slider__col-submit,
.contact-slider__col-disclaimer {
    @include span(full);
}
.contact-slider__response {
    min-height: 166px;
    display: table;
    @include span(full);
    & * {
        display: table-cell;
        vertical-align: middle;
    }
}
