///*------------------------------------*\
//    #SEARCH CRUISE
//\*------------------------------------*/


.search__col-cruise {
    @include span(full);
    @include media-query(desk) {
        @include span(1 of 3);        
    }
}
.search__col-cruise-city {    
    @include span(full);
    @include media-query(lap) {
        @include span(full);
    }
    @include media-query(desk) {
        @include span(6 of 14);
    }
}
.search__col-cruise-date {    
    @include span(full);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(desk) {
        @include span(4 of 14);
    }
}
.search-cruise__col-passenger {    
    @include span(full);
    @include media-query(lap) {
        @include span(4 of 12);
    }
    @include media-query(desk) {
        @include span(3 of 15);        
    }
}
.search-cruise__col-passengers {
    @include span(5 of 7);
    @include media-query(palm) {
        @include span(full);
    }
    & label:not(.label--error) {
        margin-bottom: 0;
    }
    @include media-query(desk) {
        .search__light--box & {
            margin-top: -37px;
        }
    }
}
.search-cruise__col-submit {    
    @include span(full);
    @include media-query(lap) {
        margin-top: $inuit-base-spacing-unit--small;
        @include span(full);
    }
    @include media-query(desk) {
        @include span(2 of 7);
    }
}
.search-cruise__col-child {
    @include span(1 of 3);
    @include media-query(lap) {
        @include span(4 of 12);
        float: right;
    }
    @include media-query(desk) {
        @include span(2 of 15);        
    }
}
