///*------------------------------------*\
//    #SEARCH FLIGHT
//\*------------------------------------*/

.search__col-flight-city {
    @include span(1 of 4);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
.search__col-flight-date {
    @include span(1 of 6);
    @include media-query(portable) {
        @include span(1 of 2);
    }
}
.search__col-flight-passengers {
    position: relative;
    @include span(1 of 6);
    @include media-query(portable) {
        @include span(full);
    }
}
.search__col-flight-passengers-block {
    @extend %picker;
    position: absolute;
    right: 0;
    min-width: 260px;
    background-color: white;
    border: 1px solid $color-frame-border;
    @include span(1 of 5);
    @include media-query(portable) {
        @include span(9 of 10);
    }
    padding-top: 15px;
}

.search-flight__col-advanced-options {
    @include span(1 of 3);
    @include media-query(palm) {
        width: 100%;
    }
}
.search-flight__col-footer {
    @include span(full);
}

.search-flight__col-check-options {
    @include media-query(lap-and-up) {
        &:not(:nth-child(1)) {
            margin-left: $inuit-base-spacing-unit--small;
        }
    }
    @include media-query(palm) {
        width: 100%;
    }
}
