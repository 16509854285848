///*------------------------------------*\
//    #DETAIL-MULTI
//\*------------------------------------*/

.detail-multi__detail-nav {
    margin: 0;
    @include media-query(desk){
        margin-top: $inuit-base-spacing-unit + $inuit-base-spacing-unit--tiny;
    }
}
.detail-multi__detail-nav-li {
    @include span(1 of 2);
}