///*------------------------------------*\
//    #BUNDLE CONTENT
//\*------------------------------------*/

.page-head-outlet {
    margin-bottom: $inuit-base-spacing-unit;
    box-shadow: inset 0 -96px 141px -42px rgba(0, 0, 0, .55);
    box-shadow: -10px -10px 10px 10px #aaaaaa;
    background: white url(/assets/aviatur_assets/img/header/animacion_cabezote.gif) no-repeat 50% 0;
    background-position-x: 35%;
    @include media-query(portable) {
        background: url(../img/header/cover.jpg) no-repeat center right;
        background-position-x: none;
        background-size: cover;
    }
    @include media-query(palm) {
        background: url(/assets/aviatur_assets/img/header/cover_outlet_moviles.jpg) no-repeat center right;
        background-position-x: none;
        background-size: cover;
    }
}

.page-head__aviatur-outlet {
    display: none;
}

.page-head__award-outlet {
    display: none;
}