///*------------------------------------*\
//    #PAGINATOR
//\*------------------------------------*/


.paginator {
    @extend %frame-element;
}

.paginator__nav {
    margin: $inuit-base-spacing-unit--small auto;
}
.paginator__switch {
    display: block;
    padding: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        margin: 0 $inuit-base-spacing-unit--small;
    }
    &.current {
        background-color: $gray-tint;
    }
    &.active {
        cursor: pointer;
        @include attention() {
            color: white;
            background-color: $color-links;
            box-shadow: 0 0 2px rgba(0,0,0,.08),0 2px 4px rgba(0,0,0,.16);
        }
        .paginator.idle & {
            cursor: default;
            @include attention() {
                color: $color-links;
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
    &.inactive {
        visibility: hidden;
    }
}