/*------------------------------------*\
    #PAGE
\*------------------------------------*/
@font-face{font-family:poppins;font-weight: lighter; src:url('/assets/aviatur_assets/font/Poppins/Poppins-Light.ttf?')}
@font-face{font-family:poppins;font-weight: bold; src:url('/assets/aviatur_assets/font/Poppins/Poppins-SemiBold.ttf?')}
html {
    font-family:poppins, sans-serif;
    font-weight: 400;
    overflow-x: hidden;
}
body {
    width: 100%;
    @include media-query(palm) {
        overflow-x: hidden;
    }
}
header {
    margin-bottom: $inuit-base-spacing-unit;
}

.page__body {
    margin-bottom: $inuit-base-spacing-unit;
}
.app-name, .not_app{
    display: none !important;
}
.dispo-flight__hopper{display:none !important}
.btn-avail__hopper{display:none !important}
.modalAvailHopper{display:none !important}
