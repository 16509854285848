///*------------------------------------*\
//    #DISPO-CRUISE
//\*------------------------------------*/

.dispo-cruise {
    position: relative;
}

/*
 * Ribbons! *o*
 */
.ribbon {
    position: absolute;
    z-index: 1;
    right: $inuit-base-spacing-unit;
    top: -$inuit-base-spacing-unit;
    background: #fff;
    width: 110px;
    height: 62px;
    box-shadow: rgba(0, 0, 0, .4) 0 2px 6px;
    @include media-query(palm){
        top: -$inuit-base-spacing-unit--small;
        margin-right: -36px;
    }
}
.ribbon img {
    width: 100%;
    padding: 5px 10px;
    margin: $inuit-base-spacing-unit 0;
}
.ribbon-corner-wrapper {
    position: absolute;
    left: -10px;
    bottom: -26px;
    width: calc(100% + 20px);;
    height: 26px;
    overflow: hidden;
}
.ribbon-left-corner,
.ribbon-right-corner {
    position: absolute;
    bottom: 16px;
    width: 55px;
    height: 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .4) 0 3px 6px;
    z-index: -1;
}
.ribbon-left-corner {
    left: 10px;
    transform: skewY(-18deg);
}
.ribbon-right-corner {
    right: 10px;
    transform: skewY(18deg);
}

.dispo-cruise .details-ticket {
    width: 58.333%;
    display: inline-block;
    margin: $inuit-base-spacing-unit--small 0 $inuit-base-spacing-unit 0;
    padding-right: $inuit-base-spacing-unit;
    @include media-query(palm){
        width: 100%;
        margin-top: $inuit-base-spacing-unit;
        padding-right: 0;
    }
}
.dispo-cruise .details-ticket p {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.dispo-cruise .details-ticket p .hidden-itinerary {
    display: none;
}

.dispo-cruise .price {
    position: relative;
    padding: $inuit-base-spacing-unit--small;
    padding-left: 0;
    border-radius: $base-round;    
    color: $color-gree-text;
    // @include blueGradient();
}
.dispo-cruise .price-label {
    @include font-size(14px);
    &.price-label-top {
        @include span(2 of 3);
        padding-left: 0;
        @include media-query(palm){
            margin-bottom: $inuit-base-spacing-unit--small/2;
        }
    }
}
.dispo-cruise .price-show-more {
    cursor: pointer;
    color: $color-main;
    @include font-weight(bold);
}
.dispo-cruise .price-conditions {
    color:$color-gree-text;
}
.dispo-cruise .price-value {
    color: $color-gree-text;
    font-size: 28px;
    @include span(2 of 3);
    padding-left: 0;
    @include font-weight(bold);
}
.dispo-cruise .price p {
    margin: 0;
}
.dispo-cruise .price .btn--submit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px $inuit-base-spacing-unit 0 0;
    width: 33%;
    @include media-query(palm){
        position: relative;
        width: 100%;
        margin: 0;
    }
}

.dispo-cruise .cruise-img img {
    width: 43.667%;
    position: absolute;
    right: 0;
    bottom: 8px;
    border-radius: 5px;
    height: 273px;
    @include media-query(palm){
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: $inuit-base-spacing-unit--small;
    }
}

/* 
 * Modal view for full itinerary 
 */
.dispo-cruise__itinerary-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-fixed;
    background-color: rgba(0, 0, 0, .5);
}
.dispo-cruise__itinerary-modal .inner-wrapper {
    position: relative;
    width: 66%;
    margin: 5% auto;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.dispo-cruise__itinerary-modal .itinerary-wrapper {
    padding: $inuit-base-spacing-unit;
}
.js-cruise-submit{
    @include media-query(palm){
        margin-bottom: 10px !important;
    }
}