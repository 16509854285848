///*------------------------------------*\
//    #CORPORATE
//\*------------------------------------*/

.corporate__img-corner {
    position: absolute;
    left: -1px;
    top: -1px;
}
.corporate__title {
    margin-left: $inuit-base-spacing-unit--large;
    padding-left: $inuit-base-spacing-unit;
}





// WHITE CONTENT
.corporate__white {
    background-color: white;
    border: 1px solid #cdcdce;
    padding: 20px 25px;
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm){
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}

.corporate__col-bolivar {
    @include span(10 of 18);
    @include media-query(lap-and-up){
        margin-top: 80px;
    }
    @include media-query(palm){
        @include span(full)
    }
}

.corporate__col-login {
    @include span(full)
}

.corporate__col-description {
    @include span(1 of 2);
    @include media-query(palm){
        @include span(full)
    }
}





// COLORED BOXES
.corporate__description-content {
    color: white;
    padding: $inuit-base-spacing-unit;
    position: relative;
    @include media-query(lap){
        min-height: 530px;
    }
    @include media-query(desk){
        min-height: 365px;
    }
}
.corporate__description-arrow {
    left: calc(50% - #{$inuit-base-spacing-unit});
    position: absolute;
    top: -1px;
    width: $inuit-base-spacing-unit--large;
}
.corporate__description-title.corporate__green {
    color: $color-corporate-green;
}
.corporate__description-title.corporate__blue {
    color: $color-corporate-blue;
}
.corporate__description-content.corporate__green {
    background-color: $color-corporate-green;
}
.corporate__description-content.corporate__blue {
    background-color: $color-corporate-blue;
}
.corporate__description-content * {
    line-height: 1.5;
}





// VIDEO FOOTER
.corporate__video {
    margin-top: 30px;
    max-width: 500px;
}
.corporate__video-play:hover {
    background-color: #444;
    box-shadow: inset 0 0 10px #333;
}
.corporate__video-play:active {
    background-color: #333;
    box-shadow: inset 0 0 10px #222;
}
.corporate__video-arrow {
    height: 46px;
}
.corporate__video-play i {
    font-size: 50px;
}
.corporate__video-play p {
    color: white;
    font-size: 12px;
    font-weight: bold;
    position:relative;
    bottom:98px;
    padding-top:31px;
}
.corporate__video-btn {
    background-color: #009BF8;
    color: white;
    display: inline-block;
    font-size: 24px;
    margin-top: 15px;
    padding: 10px 25px;
    width: 74%;
    margin-left: 5%;
    text-align:center;
    float:left;

}
.corporate__video-btn:hover {
    background-color: #444;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #333;
}
.corporate__video-btn:active, .corporate-video-btn:focus {
    background-color: #333;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #222;
}
.corporate__video-btn p{
    font-size: 16px;
    margin: 0 0 4px;
}
.corporate__video-iframe {
    width: 100%;
    min-height: 300px;
}
.corporate__phone {
    margin-top: 30px;
    font-size: 25px;
}
.corporate__container_top_cor{
    width:49%;
    font-size:19px;
    float:left;
    text-align: justify;
}
@media screen and (max-width: 921px) {
    .corporate_column_top_cor, .columnas_top_cor {width:100%;}
    .responsive_img_corp img {text-align:center; margin-bottom:20px;}
}

.corporate__center-block{
    display: block;
    float: left;
    text-align: center;
}



.corporate_column_top_cor {
    float: left;
    font-size: 19px;
    text-align: justify;
    width: 49%;
}

.corporate_column_top_cor{
    width:49%;
    /*juan*/
    font-size:19px;
    /*fin*/
    float:left;
    text-align: justify;
}

.corporate_column_top_cor .center-block{
    text-align: center;
}
.corporate__video-play {
    background-color: #555555;
    border-radius: 7px;
    float: left;
    height: 100px;
    max-width: 100px;
    padding: 20px 0;
    width: 21%;
}
.corporate-video-iframe{
    width: 100%;
    height: 500px;
}